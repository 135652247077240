//
// Typography
// ==========================================================================

// Global HTML
// ==========================================================================
html {
    @include font-smoothing();
    font-family: $base-font-family;
    text-rendering: optimizeLegibility;
    font-size: 120%;

    @include max-screen($tablet) {
        font-size: 112%;
    }

    @include max-screen($tablet) {
        font-size: 100%;
    }
}

// Headers
// ==========================================================================
h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
    margin-bottom: 1em;
    color: $blue;
    font-weight: normal;

    &.homeowner {
        color: $orange;
    }

    &.professional {
        color: $blue;
    }

    &.contractor {
        color: $teal;
    }

    &.resources {
        color: $gray;
    }
}

h1 {
    @include margin(20px 0 30px 0);
    font-size: 42px;
    line-height: 47px;
    font-family: $serif-font-bold;

    @include max-screen($desktop) {
        font-size: 32px;
        line-height: 42px;
    }

    @include max-screen($tablet) {
        @include margin(20px 0)
    }

    @include max-screen($smart) {
        @include margin(10px 0);
        font-size: 24px;
        line-height: 32px;
    }
}

h2 {
    @include margin(0 0 20px 0);
    font-size: 30px;
    font-family: $sans-font-medium;

    @include max-screen($desktop) {
        font-size: 24px;
    }

    @include max-screen($smart) {
        font-size: 22px;
    }

    @include max-screen($mobile) {
        font-size: 20px;
    }
}

h3 {
    @include margin(0 0 20px 0);
    color: $gray;
    font-size: 28px;
    font-family: $sans-font-medium;

    @include max-screen($desktop) {
        @include margin(0 0 15px 0);
        font-size: 24px;
    }

    @include max-screen($tablet) {
        @include margin(0 0 15px 0);
        font-size: 20px;
    }

    &.ellipse {
        max-height: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        display: -webkit-box;

        & {
            /* autoprefixer: off */
            box-orient: vertical;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
        }
    }
}

h4 {
    @include margin(15px 0);
    font-family: $serif-subfont-regular;
    font-size: 24px;
    letter-spacing: 1px;
    color: $blue;
    text-transform: uppercase;
}

h5 {
    font-size: 12px;
    font-family: $serif-subfont-regular;
}

h6 {
    font-size: 10px;
    font-family: $serif-subfont-regular;
}

// Links
// ==========================================================================
a {
    @include transition(all $standard-transition ease);
    @include backface-visibility(hidden);
    @include perspective(1000px);
    color: $blue;
    text-decoration: none;
    cursor: pointer;
    outline: none !important;
    outline: 0;

    &:hover {
        @include opacity(0.7);
        outline: none;
    }

    &:active {
        outline: none;
    }

    &:link {
        -webkit-tap-highlight-color: rgba($black, 0);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        @include opacity(0.5);
        cursor: not-allowed;
    }

    &.disabled {
        pointer-events: none;
    }
}

// Paragraph
// ==========================================================================
p {
    @include margin(0 0 30px 0);
    font-size: $base-font-size;
    line-height: $base-line-height;

    @include max-screen($tablet) {
        font-size: $base-font-size - 2;
        line-height: $base-line-height - 2;
        margin-bottom: 20px;
    }

    @include max-screen($smart) {
        font-size: $base-font-size - 3;
        line-height: $base-line-height - 3;
    }

    &.small {
        font-size: $base-font-size - 3;
        line-height: $base-line-height - 3;

        @include max-screen($tablet) {
            font-size: $base-font-size - 4;
            line-height: $base-line-height - 4;
        }
    }

    &.ellipse {
        max-height: $base-line-height * 4;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        display: -webkit-box;

        & {
            /* autoprefixer: off */
            box-orient: vertical;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
        }
    }

    &.ellipse-executive {
        max-height: $base-line-height * 6;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        display: -webkit-box;

        & {
            /* autoprefixer: off */
            box-orient: vertical;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
        }
        @include margin(0 0 10px 0);
    }

    &.single {
        margin-bottom: 30px !important;

        @include max-screen($tablet) {
            margin-bottom: 20px !important;
        }
    }
}

// Bold
// ==========================================================================
b,
strong {
    font-weight: normal;
    font-family: $sans-font-medium;
}

// Misc.
// ==========================================================================
blockquote {
    @include margin(1em 40px);
    @include padding(0 0 5px 20px);
    border-left: 2px solid $blue;
    font-size: 36px;
    line-height: 48px;
    color: $orange;
    font-style: italic;

    @include max-screen($tablet) {
        font-size: 24px;
        line-height: 30px;
    }

    @include max-screen($smart) {
        @include margin(1em 30px);
        font-size: 22px;
        line-height: 28px;
    }

    @include max-screen($mobile) {
        @include margin(1em 0);
        font-size: 18px;
        line-height: 24px;
    }

    p {
        color: $blue;
        font-style: italic;
        margin-bottom: 0 !important;
    }
}

cite {
    color: $blue;
}

// Code
// ==========================================================================
pre,
code {
    background-color: $gray-zero;
    @include border-radius(4px);
    border: 1px solid $gray-one;
    font-family: $fixed-font-family;
    font-size: $fixed-font-size;
    line-height: $fixed-line-height - 10;
    @include position(relative,-1px null null null);
    @include margin(0);
    @include padding(1em 1.3em);
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
