@-webkit-keyframes rotateInDownRight {
	0% {-webkit-transform-origin: right bottom; -webkit-transform: rotate(90deg); opacity: 0;}
	100% {-webkit-transform-origin: right bottom; -webkit-transform: rotate(0); opacity: 1;}
}

@-moz-keyframes rotateInDownRight {
	0% {-moz-transform-origin: right bottom; -moz-transform: rotate(90deg); opacity: 0;}
	100% {-moz-transform-origin: right bottom; -moz-transform: rotate(0); opacity: 1;}
}

@-ms-keyframes rotateInDownRight {
	0% {-ms-transform-origin: right bottom; -ms-transform: rotate(90deg); opacity: 0;}
	100% {-ms-transform-origin: right bottom; -ms-transform: rotate(0); opacity: 1;}
}

@-o-keyframes rotateInDownRight {
	0% {-o-transform-origin: right bottom; -o-transform: rotate(90deg); opacity: 0;}
	100% {-o-transform-origin: right bottom; -o-transform: rotate(0); opacity: 1;} 
}

@keyframes rotateInDownRight {
	0% {transform-origin: right bottom; transform: rotate(90deg); opacity: 0;}
	100% {transform-origin: right bottom; transform: rotate(0); opacity: 1;}
}

@mixin rotateInDownRight($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rotateInDownRight);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.rotateInDownRight { @include rotateInDownRight(); }
