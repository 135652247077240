//
// Resources
// ==========================================================================

// Main @ Resources
// ==========================================================================
main.resources {
    .section-intro {
        @include max-screen($smart) {
            padding-bottom: 60px;
        }
    }

    .section-cta {
        .items {
            @include margin(0 auto);
            max-width: 420px;
        }
    }
}

// Section Posts Important
// ==========================================================================
.section-posts-important {
    background-color: rgb(251, 251, 251);
    text-align: center;
    padding-bottom: 0;

    p {
        @include margin(0 auto 60px auto);
        max-width: 520px;

        @include max-screen($tablet) {
            margin-bottom: 30px;
        }
    }

    .row {
        padding-bottom: 50px;

        @include max-screen($tablet) {
            padding-bottom: 20px;
        }
    }

    .post-pic {
        @include border-radius(4px);
        @include margin(0);
        display: block;
        min-height: 160px;
        min-width: 160px;
        @include max-screen("1120px") {
            min-height: 120px;
            min-width: 130px;
        }
         @include max-screen("840px") {
            min-height: 100px;
            min-width: 100px;
        }
        overflow: hidden;
        position: relative;

        &:after {
            @include position(absolute, 0 0 0 0);
            @include opacity(0.8);
            background: $gray;
            content: "";
            display: block;
        }

        h4 {
            @include position(absolute);
            @include center();
            @include margin(0 auto);
            font-size: 17px;
            text-align: center;
            width: 90%;
            color: $white;
            z-index: $z-content;
            @include max-screen("1120px") {
                font-size: 70%;
            }
            @include max-screen("900px") {
                font-size: 50%;
            }
        }

        img {
            @include position(absolute, 50% auto auto 50%);
            @include transform(translate(-50%, -50%));
            min-height: 100%;
            min-width: 100%;
            display: block;
        }
    }
}

// Section Posts
// ==========================================================================
.section-posts-intro {
    .column {
        @include box-shadow(-1px, 0, 0, 0, $gray-one, true);

        @include max-screen($tablet) {
            @include box-shadow(0, 0, 0, 0, transparent);
            border-bottom: 1px solid $gray-one;
            padding-bottom: 30px;
        }

        @include max-screen($smart) {
            padding-bottom: 20px;
        }

        &:last-child {
            @include box-shadow(0, 0, 0, 0, transparent);

            @include max-screen($tablet) {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .row {
        @include margin(0 0 60px 0);
    }

    .post {
        min-height: 500px;

        @include max-screen($tablet) {
            min-height: inherit;
        }

        .post-pic {
            @include border-radius(4px);
            @include margin(0 auto 30px auto);
            display: block;
            min-height: 160px;
            overflow: hidden;
            position: relative;

            @include max-screen($tablet) {
                @include margin(0 auto 20px auto);
                min-height: 140px;
            }

            @include max-screen($smart) {
                min-height: initial;
            }

            img {
                @include position(absolute, 50% auto auto 50%);
                @include transform(translate(-50%, -50%));
                min-height: 100%;
                min-width: 100%;
                display: block;

                @include max-screen($smart) {
                    @include transform(none);
                    @include position(relative, auto auto auto auto);
                }
            }
        }

        h3 {
            margin-top: 10px;
            margin-bottom: 30px;

            @include max-screen($tablet) {
                margin-bottom: 15px;
            }
        }

        p {
            color: $gray;
        }

        .date {
            color: $gray-two;
        }

        .read-more,
        .date {
            font-size: 12px;
            font-family: $sans-font-bold;
            text-transform: uppercase;
            letter-spacing: 1.8px;

            @include max-screen($smart) {
                font-size: 10px;
            }
        }
        .read-more{
            font-weight:bold;
        }
    }
}
