//
// Professional
// ==========================================================================

// Main @ Professional
// ==========================================================================
main.professionals {
    .section-plans {
        background-color: rgb(251, 251, 251);

        fieldset .row .column:first-child {
            padding-left: 1em;
        }

        fieldset .row .column:last-child {
            padding-right: 1em;
        }
    }

    .section-reviews {
        border-bottom: 1px solid $gray-one;
        background: $gray-zero;
    }
}

// Reps
// ==========================================================================
.section-reps {
    text-align: center;
    background-color: rgb(251, 251, 251);
    figure {
        @include size(90px);
        @include margin(20px auto);
        position: relative;
        display: block;

        img {
            @include border-radius(100%);
            width: 100%;
            display: block;
        }
    }

    p {
        margin-right: auto;
        margin-left: auto;
        max-width: 740px;
    }

    .name {
        font-size: 14px;
        font-family: $sans-font-bold;
        letter-spacing: 1.2px;
        color: $blue;
        text-transform: uppercase;
    }

    .meta {
        @extend .name;
        @include margin(0 auto 30px auto);
        color: $gray-two;
        font-size: 12px;

        @include max-screen($smart) {
            margin-bottom: 20px;
        }

        & .meta-icon {
            color: #F2693E;
            padding-right: 15px;
            text-transform: none;
        }
    }
}

// Benefits
// ==========================================================================
.section-benefits {
    background: $gray-zero;
    border-top: 1px solid $gray-one;

    hr {
        margin-top: 60px;

        @include max-screen($tablet) {
            margin-top: 30px;
        }

        @include max-screen($smart) {
            @include margin(15px auto);
        }
    }
}

// Tabs
// ==========================================================================
.tabs {
    @include margin(0);
    @include clearfix;
    background: $gray-zero;

    li {
        @include padding(15px 5px);
        @include transition(all $quick-transition ease);
        border-bottom: 2px solid $gray-one;
        background: $gray-zero;
        width: 50%;
        float: left;
        display: inline-block;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        color: $gray-two;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        font-family: $sans-font-bold;

        &:hover {
            @include opacity(0.7);
        }

        &.current {
            color: $blue;
            border-color: $blue;
        }
    }
}

.tab-content {
    display: none;

    &.current {
        display: inherit;
    }
}
