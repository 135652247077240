//
// Helper Classes
// ==========================================================================

// Selections
// ==========================================================================
::selection         { background: #AAAAAA;}//$orange; }
::-moz-selection    { background: #AAAAAA;}//$orange; }

// Default
// ==========================================================================
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

// Visibility
.visible-all,
.visible-small,
.visible-large,
.visible-huge {
  visibility: visible;
}
.hidden-small{
    @include max-screen($smart) {
        display: none;
    }
}
.hidden-all,
.hidden-large,
.hidden-huge {
  display: none;
}
.hide,
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.overflow-hidden {
  overflow: hidden;
}
.hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
// Float
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Layout
.italic     { font-style: italic; }
.center     { text-align: center; }
.pull-left  { float: left; }
.pull-right { float: right; }

// Fluid Video
// ==========================================================================
.fluid-video {
  @include border-radius(6px);
  @include box-shadow(0, 30px, 40px, 0, rgba($black, 0.1));
  z-index: $z-content;
  position: relative;
  overflow: hidden;
  iframe {
    display: block;
    max-width: 100%;
  }
}

// Print Styles
// ==========================================================================
@media print {
  * {
    background: transparent !important;
    color: $black !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  // Don't show links for images, or javascript/internal links
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid $black;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; // h5bp.com/t
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
