//
// Homeowner
// ==========================================================================

// Main @ Homeowner
// ==========================================================================
main.homeowner {
  .section-reviews {
    border-top: 1px solid $gray-one;
    background: $gray-zero;
  }
  .section-plans {
    > .container {
      max-width: 1040px;
    }
    .pricing-title {
      h3 {
        float: left;
        @include max-screen($desktop) {
          float: none;
        }
      }
    }
  }
}

// Homeowner Features
// ==========================================================================
.section-features {
  text-align: center;
  background-color: rgb(251, 251, 251);
  p {
    @include margin(0 auto 60px auto);
    max-width: 740px;
  }
  .item {
    padding-top: 70px;
    @include max-screen($smart) {
      padding-top: 50px;
    }
    &:hover {
      h5 {
        color: $orange;
        &[data-icon]:before {
          color: $orange;
        }
      }
    }
    h5 {
      @include transition(all $quick-transition ease);
      font-family: $sans-font-bold;
      color: $gray;
      letter-spacing: 1.8px;
      font-size: 16px;
      text-transform: uppercase;
      position: relative;
      @include max-screen($smart) {
        font-size: $base-font-size - 4;
        line-height: $base-line-height - 4;
      }
      &[data-icon]:before {
        @include transition(all $quick-transition ease);
        @include position(absolute, -70px auto auto auto);
        @include center(x);
        display: block;
        font-size: 56px;
        color: $gray-two;
      }
      &[data-icon="D"]:before { font-size: 58px; }
      &[data-icon="G"]:before { font-size: 54px; }
      &[data-icon="I"]:before { font-size: 50px; }
    }
    p {
      max-width: 344px;
      font-size: 16px;
      line-height: 24px;
      @include max-screen($smart) {
        font-size: $base-font-size - 4;
        line-height: $base-line-height - 6;
      }
    }
  }
}
