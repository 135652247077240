//
// Contact
// ==========================================================================

// Main @ Contact
// ==========================================================================
main.contact {
    .section-intro.contact {
        background-color: rgb(251,251,251);
        h1 {
            font-size: 42px;
            line-height: 47px;

            @include max-screen($desktop) {
                font-size: 32px;
                line-height: 42px;
            }

            @include max-screen($tablet) {
                @include margin(20px 0)
            }

            @include max-screen($smart) {
                @include margin(10px 0);
                font-size: 24px;
                line-height: 32px;
            }
        }

        hr {
            margin-right: auto;
            margin-left: auto;
            max-width: 1100px;
            position: relative;
            z-index: $z-content;
        }
    }
}

// Section Contact
// ==========================================================================
.section-contact {
    background-color: rgb(251,251,251);
    .intro {
        margin-right: auto;
        margin-left: auto;
        max-width: 590px;
        text-align: center;
    }
}
