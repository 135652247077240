@-webkit-keyframes fadeInDown {
	0% {opacity: 0; -webkit-transform: translateY(-20px);}
	100% {opacity: 1; -webkit-transform: translateY(0);}
}

@-moz-keyframes fadeInDown {
	0% {opacity: 0; -moz-transform: translateY(-20px);}
	100% {opacity: 1; -moz-transform: translateY(0);}
}

@-ms-keyframes fadeInDown {
	0% {opacity: 0; -ms-transform: translateY(-20px);}
	100% {opacity: 1; -ms-transform: translateY(0);}
}

@-o-keyframes fadeInDown {
	0% {opacity: 0; -ms-transform: translateY(-20px);}
	100% {opacity: 1; -ms-transform: translateY(0);}
}

@keyframes fadeInDown {
	0% {opacity: 0; transform: translateY(-20px);}
	100% {opacity: 1; transform: translateY(0);}
}

@mixin fadeInDown($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(fadeInDown);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.fadeInDown { @include fadeInDown(); }