//
// Form Elements
// ==========================================================================

// Structure
// ==========================================================================
fieldset {
  @include margin(0);
  @include padding(0);
  @include clearfix;
  display: block;
  @include max-screen($smart) {
    margin-bottom: 20px;
  }
  &.fieldset-last {
    @include margin(0 0 40px 0);
  }
  &.submit {
    @include margin(50px 0 0 0);
    text-align: center;
    .btn {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.submit-flow {
    @include margin(60px 0);
    @include padding(40px 0 0 0);
    @include clearfix;
    border-top: 1px solid $gray-one;
    .btn-next {
      float: right;
    }
    .btn-prev {
      float: left;
      i {
        @include margin(0 20px 0 0);
        @include max-screen($tablet) {
          @include margin(0 10px 0 0);
        }
      }
    }
    @include max-screen($mobile) {
        margin: 0 !important;
        padding: 150px 0 0 0 !important;
    }
  }
  .fieldset-title {
    @include margin(20px 0 10px 0);
    width: 100%;
    overflow: hidden;
    font-family: $sans-font-medium;
    display: table;
    white-space: nowrap;
    color: $blue;
    font-size: 16px;
    @include max-screen($tablet) {
      @include margin(20px 0);
      font-size: 14px;
    }
    &:after {
      border-top: 1px solid $gray-one;
      content: "";
      display: table-cell;
      position: relative;
      top: 13px;
      width: 100%;
    }
    &:after {
      left: 1.5%;
    }
  }
  .field-label {
    @include margin(0 0 8px 0);
    font-family: $sans-font-medium;
    font-size: $base-font-size - 7;
    line-height: $base-font-size - 7;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $gray;
    display: block;
    vertical-align: middle;
    .info {
      @include margin(0 0 0 5px);
      @include position(relative, 4px 0 0 0);
      font-size: 18px;
      color: $orange;
      text-transform: none !important;
      letter-spacing: 0 !important;
    }
    &.light {
        color: $gray-three;
    }
  }
  .field-wrap {
    position: relative;
    @include margin(0 0 10px 0);
    @include padding(0);
    @include clearfix;
    @include max-screen($smart) {
      margin-bottom: 10px;
    }
    .column {
      &:last-child {
        @include max-screen($tablet) {
          margin-bottom: 0;
        }
      }
    }
  }
  .row {
    .column {
      position: relative;
      @include max-screen($tablet) {
        @include margin(0 0 20px 0);
        @include padding(0);
      }
      &:first-child {
        padding-left: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
      &:last-child {
        padding-right: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
    }
    &:last-child {
      .field-wrap {
        @include margin(0);
      }
    }
  }
}

// Inputs
// ==========================================================================
input,
#{$all-text-inputs} {
  @include transition(all $quick-transition ease);
  @include placeholder {
    @include transition(all $quick-transition ease);
    color: $gray-three;
  }
  @include margin(0);
  @include padding(11px 20px);
  @include border-radius(4px);
  @include appearance(none);
  font-family: $sans-font-light;
  box-sizing: border-box;
  border: 1px solid $gray-two;
  background: $gray-zero;
  color: $gray;
  font-size: $base-font-size - 4;
  line-height: initial;
  width: 100%;
  outline: none;
  // Responsive
  @include max-screen($tablet) {
    @include padding(10px 15px);
    font-size: $base-font-size - 6;
  }
  @include max-screen($smart) {
    @include padding(10px);
  }
  @include max-screen($mobile) {
    @include padding(8px);
  }
  &.dark {
    background: $gray-one;
    border-color: $gray-one;
  }
  // Validation for Input
  &.error {
    background: rgba($red, 0.1);
    @include placeholder {
      color: $red;
    }
    color: $red;
    border-color: $red;
  }
  &.success {
    @include placeholder {
      color: $green;
    }
    color: $green;
    border-color: $green;
  }
}
// Hover
input:hover,
#{$all-text-inputs-hover} {

}
// Focus
input:focus,
#{$all-text-inputs-focus} {
  border-color: $gray-dark;
}

// Disabled Inputs
// ==========================================================================
input[disabled=disabled],
textarea[disabled=disabled],
input[disabled] {
  border-color: $gray-one;
  background: $gray-zero;
  color: $gray-one;
  cursor: not-allowed !important;
 &.readable {
    color: $gray-two;
  }
}

// Selects
select[disabled] {
  cursor: not-allowed !important;
}
// ==========================================================================
.select {
  @extend input;
  @include appearance(none);
  @include padding(0 !important);
  position: relative;
  outline: none;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  &:after {
    @include size(12px);
    @include position(absolute, auto 20px auto auto);
    @include center(y);
    pointer-events: none;
    color: $gray-two;
    content: "m";
    font-size: 12px;
    font-family: $icon-font-family;
    @include max-screen($tablet) {
      right: 15px;
      @include size(10px);
      font-size: 10px;
    }
    @include max-screen($smart) {
      right: 10px;
    }
    @include max-screen($mobile) {
      right: 8px;
    }
  }
  select {
    @include appearance(none);
    @include padding(11px 20px);
    color: $gray;
    cursor: pointer;
    text-transform: none;
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    z-index: 1;
    position: relative;
    @include max-screen($tablet) {
      @include padding(10px 15px);
    }
    @include max-screen($smart) {
      @include padding(10px);
    }
    @include max-screen($mobile) {
      @include padding(8px);
    }
    &.error {
        background: rgba($red, 0.1);
        @include placeholder {
          color: $red;
        }
        color: $red;
        border-color: $red;
    }
    &:focus {
      outline: none;
    }
  }
}

// Textarea
// ==========================================================================
textarea {
  @extend input;
  resize: none; // none|horizontal|vertical|both
  @include margin(0);
  line-height: 18px !important;
  height: 120px;
}
textarea.vert { resize: vertical; }
textarea.no-resize { resize: none; }

// Checkbox
// ==========================================================================
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  margin-top: 10px;
  padding-left: 35px;
  line-height: 26px;
  cursor: pointer;
  text-overflow: ellipsis;
  display: inline-block;
  color: $gray;
  font-size: $base-font-size - 3;
  font-family: $sans-font-light;
  @include max-screen($tablet) {
    font-size: $base-font-size - 6;
    white-space: inherit;
    overflow: visible;
  }
  @include max-screen($smart) {
    padding-left: 30px;
    line-height: 22px;
  }
}

// Checkbox
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  @include transition(all $quick-transition ease);
  @include position(absolute, 0 null null 0);
  @include size(24px);
  @include border-radius(4px);
  border: 1px solid $gray-two;
  background: $white;
  box-shadow: none;
  content: '';
  @include max-screen($smart) {
    @include size(22px);
  }
}

// Checkbox Checked Only
[type="checkbox"]:checked + label {
  font-family: $sans-font-medium;
  color: $blue;
}
[type="checkbox"]:checked + label:before {
  background: $white;
  border-color: $blue;
}

// Checked Mark
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  @include transition(all $quick-transition ease);
  @include position(absolute, -1px null null 5px);
  content: 'u';
  font-family: $icon-font-family;
  font-size: $base-font-size - 4;
  color: $blue;
  @include max-screen($smart) {
    font-size: 10px;
    top: 0;
    left: 7px;
  }
}
// Checked Mark Changes
[type="checkbox"]:not(:checked) + label:after {
  @include opacity(0);
  @include transform(scale(0));
}
[type="checkbox"]:checked + label:after {
  @include opacity(1);
  @include transform(scale(1));
}
// Disabled Not Checked
[type="checkbox"]:disabled:not(:checked) + label:before {
  border-color: $gray-one;
  background: $white;
}

// Disabled Checked
[type="checkbox"]:disabled:checked + label:before {
  background: $gray-one;
  border-color: $gray-one;
  box-shadow: none;
}
[type="checkbox"]:disabled:checked + label {
  color: $gray-one;
}

// Accessibility
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  // Nothing rendered
}
// Hover
label:hover:before {
  // Nothing rendered
}

// Radio
// ==========================================================================
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  margin-top: 10px;
  padding-left: 35px;
  line-height: 26px;
  cursor: pointer;
  text-overflow: ellipsis;
  display: inline-block;
  color: $gray;
  font-size: $base-font-size - 4;
  font-family: $sans-font-light;
  @include max-screen($tablet) {
    font-size: $base-font-size - 6;
    white-space: inherit;
    overflow: visible;
  }
  @include max-screen($smart) {
    padding-left: 30px;
    line-height: 22px;
  }
}

// Radio Button
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  @include position(absolute, 0 null null 0);
  @include size(24px);
  @include border-radius(50%);
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid $gray-two;
  background: $white;
  box-shadow: none;
  content: '';
  @include max-screen($smart) {
    @include size(22px);
  }
}

// Inner Radio Element
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  @include transition(all $quick-transition ease);
  @include position(absolute, 2px null null 2px);
  @include transform-origin(center);
  @include border-radius(50%);
  @include size(20px);
  background: $white;
  content: '';
  display: inline-block;
  @include max-screen($smart) {
    @include size(18px);
  }
}

// Disabled Radio Not Checked
[type="radio"]:disabled:not(:checked) + label:before {
  border-color: $gray-one;
  box-shadow: none;
}
[type="radio"]:disabled:not(:checked) + label {
  color: $gray-one;
}

// Disabled Radio Checked
[type="radio"]:disabled:checked + label:before {
  background: $gray-one;
  border: $gray-one;
}
[type="radio"]:disabled:checked + label {
  color: $gray-one;
}

// Radio Checked Changes
[type="radio"]:checked + label {
  font-family: $sans-font-medium;
  color: $blue;
}
[type="radio"]:checked + label:before {
  background-color: $blue;
  border-color: $blue;
}
[type="radio"]:checked + label:after {
  @include size(10px);
  @include transform-origin(center);
  top: 7px;
  left: 7px;
  @include max-screen($smart) {
    @include size(10px);
    top: 6px;
    left: 6px;
  }
}

// Radio Transitions
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  @include transition(all, $quick-transition ease);
}

// Checkbox & Radio Wrap
// ==========================================================================
.checkbox-wrap {
  @include margin(0 20px 10px 0);
  float: left;
  display: inline-block;
  .info {
    @include position(relative, 4px auto auto auto);
    @include margin(0 0 0 3px);
    display: inline-block;
    color: $orange;
  }
}
.checkbox-wrap-block {
  @include margin(0 0 10px 0);
  display: block;
  position: relative;
  .input-option {
    @include position(absolute, 7px auto auto 34px);
    z-index: $z-content;
    input {
      @include size(45px 24px);
      @include padding(0);
      line-height: 26px;
      text-align: center;
    }
  }
  label {
    padding-left: 85px !important;
    @include max-screen($desktop) {
      font-size: 12px !important;
    }
  }
  .info {
    @include position(relative, 4px auto auto auto);
    @include margin(0 0 0 3px);
    display: inline-block;
    color: $orange;
  }
}

// Input Icons (Right Side)
// ==========================================================================
.field-validation-error {
    @include position(absolute, 0 15px auto auto);
    @include center(y);
    text-align: center;
    font-size: $base-font-size;
    font-family: $sans-font-bold;
    display: block;
    pointer-events: none;
    z-index: $z-content;
    text-align: right;
    color: $red;
    text-transform: uppercase;
    font-size: 10px;
}

.field-wrap {
  &.error,
  &.submit,
  &.info {
    input {
      padding-right: 80px;
    }
  }
  &.submit {
    &:after {
      content: "";
    }
    .btn-input {
      @include position(absolute, 0 6px auto auto);
      @include center(y);
      @include size(30px);
      @include border-radius(4px);
      z-index: $z-content;
      background: $orange;
      display: block;
      text-align: center;
      &:after {
        @include position(relative, -4px auto auto auto);
        font-size: 12px;
        line-height: 30px;
        text-transform: none;
        color: $white;
        content: "j";
        font-family: $icon-font-family;
      }
    }
  }
  &.info {
    &:after {
      content: "";
    }
    .info {
      @include position(absolute, 0 15px auto auto);
      @include center(y);
      height: 20px;
      display: block;
      color: $orange;
    }
  }
}

// Input Icons (Left Side)
// ==========================================================================
.field-wrap {
  &.search {
    input {
      padding-left: 40px;
    }
    &:after {
      @include position(absolute, 0 auto auto 15px);
      @include center(y);
      text-align: center;
      font-family: $icon-font-family;
      font-size: $base-font-size;
      display: block;
      pointer-events: none;
      z-index: $z-content;
      @include max-screen($smart) {
        font-size: $base-font-size + 4;
      }
    }
  }
  &.dollar {
    &:before {
      @include position(relative, 0 auto auto 15px);
      @include center(y);
      content: "$";
      color: $gray-two;
      font-family: $sans-font-bold;
      font-size: $base-font-size - 4;
    }
    input {
      padding-left: 34px;
    }
  }
  &.search {
    &:after {
      content: '"';
      font-size: 16px;
    }
  }
}

// Input Well
// ==========================================================================
.input-well {
  @include padding(25px 30px);
  @include box-shadow(0, 2px, 4px, 0, $gray-one);
  @include border-radius(4px);
  border: 2px solid $gray-one;
  background: rgba($gray-zero, 0.30);
  @include max-screen($desktop) {
    @include padding(20px);
  }
  @include max-screen($tablet) {
    @include padding(15px);
  }
  @include max-screen($smart) {
    @include padding(0);
    box-shadow: none;
    border: 0;
    background: transparent;
  }
}

// Conditionals
// ==========================================================================
input[type="search"]::-ms-clear {
  // Removes close icon - IE
  display: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
