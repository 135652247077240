.footer-row ul {
    display: inline;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;

        i {
            vertical-align: sub;

            ul {
                display: block;
            }
        }

        ul {
            bottom: 123px;
            display: none;
            right: 95px;
            position: absolute;
            width: 190px;

            li {
                background: #415E74;
                display: block;
                margin-left: auto !important;
                margin-top: 3px;
                width: 100%;

                a {
                    display:block !important;
                    padding-left: 3px;
                }
            }
        }
    }
}
.privacy a {
    color: white;
}
 /* Ipad pro */
@media only screen
and (min-device-width: 1023px)
and (max-device-width: 1366px)
and (orientation: portrait) {
    .brandBox {
        li a {
            font-size: 10px;
        }

        ul {
            bottom: 148px !important;
            display: grid !important;
            right: 290px !important;
            width: 38% !important;
        }
    }
}

/* Ipad pro */
@media only screen
and (min-device-width: 1366px)
and (max-device-width: 1500px)
and (orientation: landscape) {
    .brandBox {
        li a {
            font-size: 9px !important;
        }

        ul {
            width: 13% !important;
        }
    }
}

/* Ipad */
@media only screen
and (min-device-width : 768px)
and (max-device-width: 1022px)
and (orientation: portrait) {
    .brandBox ul {
        bottom: 106px !important;
        display: grid !important;
        right: 236px !important;
        width: 48% !important;
    }
}

/* Ipad */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1050px)
and (orientation: landscape) {
    .brandBox ul {
        bottom: 151px !important;
        display: grid !important;
        right: 300px !important;
        width: 35% !important;
    }
}

/* Phone */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 767px)
and (orientation: portrait)  {
    .brandBox ul {
        left: unset !important;
        bottom: unset !important;
        position: relative !important;
        right: unset !important;
        width: unset !important;
    }
}

/* Phone */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 767px)
and (orientation: landscape)  {
    .brandBox ul {
        bottom: 132px !important;
        display: grid !important;
        position: absolute !important;
        right: 155px !important;
        width: 56% !important;

    }
}


