//
// Order
// ==========================================================================

// Main @ Order
// ==========================================================================
main.order {
  &.confirm {
    .section-flownav {
      border-bottom: 1px solid $gray-one;
      height: 61px;
      @include max-screen($desktop) {
        height: auto;
      }
    }
  }
  padding-top: $header-height + $order-header-height;

  /*tablet device sizing*/
  @include max-screen($tablet) {
      padding-top: $header-height - 50 + $order-header-height;
  }
  /*Smart device sizing*/
  @include max-screen($mobile) {
      padding-top: $header-height - 50;
  }

  .section-flownav {
    border-bottom: none;
    height: 60px;
    @include max-screen($desktop) {
      height: auto;
    }
    @include max-screen($smart) {
      border-bottom: 1px solid $gray-one;
    }
    ul {
      width: auto;
      li {
        width: auto;
        @include max-screen($desktop) {
          width: 33.33%;
        }
      }
    }
    .btn-flownav {
      @include max-screen($huge) {
        @include padding(0 50px);
      }
      @include max-screen($desktop) {
        @include padding(10px 20px);
        line-height: 30px;
      }
      @include max-screen($tablet) {
        @include margin(0);
        @include padding(10px 15px 5px 15px);
        font-size: 10px;
      }
      @include max-screen($smart) {
        @include padding(10px);
      }
    }
  }
  .section-plans {
    padding-top: 0;
    @include max-screen($smart) {
      padding-top: 3em;
    }
    background-color: rgb(251, 251, 251);
    .pricing-title {
      @include clearfix;
      .btn {
        margin-top: 10px;
      }
      .price-tag {
        float: right;
        @include max-screen($desktop) {
          float: none;
        }
      }
    }
    fieldset.submit-flow {
      @include margin(60px 0);
      @include padding(0 !important);
      @include max-screen($mobile) {
        margin: 0 !important;
        padding: 115px 0 0 0 !important;
      }
    }
    .add-ons,
    .coupon {
      @include max-screen($tablet) {
        .column {
          @include padding(0 !important);
          float: none;
          width: 100%;
        }
      }
    }
  }
}

// Section Confirm Order
// ==========================================================================
.section-confirm-order {
  @include padding(0 0);
  hr {
    @include max-screen($tablet) {
      display: none;
    }
  }
  .total {
    .well {
      @include margin(20px 0 0 0);
      @include padding(20px);
      h4 {
        @include margin(0);
        line-height: 18px;
        display: inline-block;
        color: $orange;
      }
    }
  }
  .survey {
    @include margin(0 0 40px 0);
    @include clearfix;
    .column {
      text-align: center;
      @include max-screen($tablet) {
        border-bottom: 1px solid $gray-one;
        padding-bottom: 40px !important;
      }
    }
    .checkbox-wrap {
      @include margin(0);
      width: 50%;
      position: relative;
      float: left;
      @include max-screen($tablet) {
        float: none;
        width: auto;
      }
      label {
        @include max-screen($tablet) {
          padding-left: 25px;
        }
      }
      span {
        @include position(absolute, -30px auto auto 0);
        width: 24px;
      }
    }
    .stars {
      @include margin(0 0 20px 0);
      @include padding(0 0 10px 0);
      text-align: center;
      border-bottom: 1px solid $gray-one;
      @include max-screen($tablet) {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
    i {
      font-size: 16px;
      color: $orange;
      text-align: center;
      display: inline-block
    }
  }
  .well {
    @include margin(0);
    @include clearfix;
    position: relative;
    @include max-screen($tablet) {
      @include padding(10px 80px 10px 20px);
    }
    h4 {
      font-family: $sans-font-medium;
      letter-spacing: 0;
      text-transform: capitalize;
      font-size: 18px;
      @include max-screen($tablet) {
        @include margin(0);
        font-size: 14px;
      }
    }
    p {
      line-height: 20px;
      color: $gray;
      font-family: $sans-font-regular;
    }
    .edit {
      @include position(absolute, auto 30px auto auto);
      @include center(y);
      @include border-radius(4px);
      @include padding(0 12px);
      @include size(auto 24px);
      line-height: 24px;
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $white;
      background: $orange;
      @include max-screen($tablet) {
        right: 20px;
      }
    }
    .cost {
      color: $orange;
      font-family: $sans-font-medium;
      float: right;
    }
  }
  dl > dd {
    @include clearfix;
    padding-right: 30px;
    position: relative;
    font-family: $sans-font-regular;
    border-bottom: 1px solid $gray-one;
    line-height: 36px;
    &:last-child {
      border-bottom: none;
    }
    .remove {
      @include position(absolute, auto 0 auto auto);
      @include center(y);
      color: $red;
      font-size: 9px;
      display: inline-block;
      &:after {
        @include position(absolute, 0 auto auto -10px);
        @include center(y);
        @include size(1px 20px);
        background: $gray-one;
        content: "";
        display: block;
      }
    }
    .item {
      font-size: 14px;
      @include max-screen($tablet) {
        @include ellipsis;
        max-width: 80%;
        float: left;
      }
      @include max-screen($smart) {
        font-size: 12px;
      }
    }
    .status,
    .cost {
      float: right;
    }
    .cost {
      font-family: $sans-font-bold;
      font-size: 14px;
    }
  }
}

// Order Header
// ==========================================================================
.order-header {
  @include transition(all $standard-transition ease);
  /*@include position(fixed, $header-height 0 auto 0);*/
  z-index: $z-above-content;
  background: rgba($orange, 0.9);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  @include max-screen($desktop) {
    top: $header-height;
  }
  @include max-screen($tablet) {
    top: $header-height;
  }
  @include max-screen($smart) {
    text-align: center;
    top: auto !important;
    bottom: 0;
  }
  .pricing-items {
    margin-right: 215px;
    @include max-screen($smart) {
      margin-right: 0;
    }
  }
  &.order-is-scrolled {
    top: 90px;
  }
  h4 {
    @include margin(0);
    font-family: $sans-font-medium;
    color: $white;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    @include max-screen($tablet) {
      display: none;
      font-size: 20px;
    }
    @include max-screen($smart) {
      display: none;
    }
  }
  .pricing-title {
    @include margin(0);
    @include position(absolute, 0 0 auto auto);
    @include center(y);
    @include max-screen($smart) {
      @include position(relative, auto auto auto auto);
      transform: none;
    }
    @include max-screen($tablet) {
        padding-top: 3px;
    }
    .price-tag {
      @include margin(0);
      top: 2px;
    }
  }
  ul {
    @include margin(0 0 0 10px);
    display: inline-block;
    @include max-screen($smart) {
      text-align: center;
      @include margin(0 auto 0 0);
      display: block;
    }
    li {
      @include margin(0 0 0 10px);
      @include border-radius(18px);
      @include padding(0 20px);
      display: inline-block;
      height: 36px;
      line-height: 32px;
      font-size: 14px;
      border: 2px solid $white;
      color: $white;
      font-family: $sans-font-medium;
      &:first-child {
        margin-left: 0;
      }
      @include max-screen($tablet) {
        @include margin(0 0 0 5px);
        @include padding(0 10px);
        height: 30px;
        line-height: 24px;
        font-size: 13px;
      }
      @include max-screen($smart) {
        @include padding(0 10px);
        height: 24px;
        line-height: 20px;
        font-size: 12px;
      }
      a {
        @include margin(0 0 0 10px);
        @include size(12px);
        font-size: 12px;
        display: inline-block;
        color: $white;
        vertical-align: middle;
        line-height: 12px;
        @include max-screen($smart) {
          @include margin(0 0 0 5px);
          @include size(8px);
          line-height: 8px;
          font-size: 8px;
        }
      }
    }
  }
}
