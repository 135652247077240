//
// Reset
// ==========================================================================
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  vertical-align:baseline;
  background:transparent;
}

html, body {
  font-size: 100%;
}

// Corrects block display not defined in IE8/9 & FF3
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

// Corrects inline-block display not defined in IE8/9 & FF3
audio, canvas, video {
  display: inline-block;
}

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls]) {
  display: none;
}

// Addresses styling for 'hidden' attribute not present in IE8/9, FF3, S4
[hidden] {
  display: none;
}

// Prevents iOS text size adjust after orientation change, without disabling user zoom
// www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Addresses font-family inconsistency between 'textarea' and other form elements.
html, button, input, select, textarea {
  font-family: sans-serif;
}

a {
  // Addresses outline displayed oddly in Chrome
  &:focus {
    outline: thin dotted;
    // Webkit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  // Improves readability when focused and also mouse hovered in all browsers
  // people.opera.com/patrickl/experiments/keyboard/test
  &:hover, &:active {
    outline: 0;
  }
}

// Addresses styling not present in IE8/9, S5, Chrome
abbr[title] {
  border-bottom: 1px dotted;
}

// Addresses style set to 'bolder' in FF3+, S4/5, Chrome
b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

// Addresses styling not present in S5, Chrome
dfn {
  font-style: italic;
}

// Addresses styling not present in IE8/9
mark {
  background: #ff0;
  color: #000;
}

// Corrects font family set oddly in S4/5, Chrome
// en.wikipedia.org/wiki/User:Davidgothberg/Test59
pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

// Improves readability of pre-formatted text in all browsers
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

// Addresses quote property not supported in S4
blockquote, q {
  quotes: none;
  &:before, &:after {
    content: '';
    content: none;
  }
}
ul {
  list-style: none;
}

small {
  font-size: 75%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

nav {
  ul, ol {
    list-style: none;
    list-style-image: none;
  }
}

// Removes border when inside 'a' element in IE8/9, FF3
img {
  border: 0;
  height: auto;
  max-width: 100%;
  display: block;
  -ms-interpolation-mode: bicubic;
}

.post-body.text img{
    display:inherit;
}

// Corrects overflow displayed oddly in IE9
svg:not(:root) {
  overflow: hidden;
}

// Define consistent border, margin, and padding
// fieldset {
//  border: 1px solid #c0c0c0;
//  margin: 0 2px;
//  padding: 0.35em 0.625em 0.75em;
// }

legend {
  border: 0; // Corrects color not being inherited in IE8/9
  padding: 0;
  white-space: normal; // Corrects text not wrapping in FF3
}

button, input, select, textarea {
  font-size: 100%; // Corrects font size not being inherited in all browsers
  margin: 0; // Addresses margins set differently in FF3+, S5, Chrome
  vertical-align: baseline; // Improves appearance and consistency in all browsers
}

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer; // Improves usability and consistency of cursor style between image-type 'input' and others
  -webkit-appearance: button; // Corrects inability to style clickable 'input' types in iOS
}

// Re-set default cursor for disabled elements
button[disabled], input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; // Addresses box sizing set to content-box in IE8/9
  padding: 0; //Removes excess padding in IE8/9
}

input[type="search"] {
  -webkit-appearance: textfield; // Addresses appearance set to searchfield in S5, Chrome
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; // Addresses box-sizing set to border-box in S5, Chrome (-moz to future-proof)
  box-sizing: content-box;
}

// Removes inner padding and search cancel button in S5, Chrome on OS X
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto; // Removes default vertical scrollbar in IE8/9
  vertical-align: top; // Improves readability and alignment in all browsers
}

// Remove most spacing between table cells
table {
  border-collapse: collapse;
  border-spacing: 0;
}
