@import "../content/css/modules/media-queries";

.limits-exclusions {
    color: #1F506F;
    font-family: brandon_grotesquemedium, serif;
    width: 1000px;

    .visibleIfTablet {
        display: initial;

        @include min-screen(1280px) {
            display: none;
        }
    }

    .hiddenIfTablet {
        display: initial;

        @include max-screen(1279px) {
            display: none;
        }
    }

    .bottom-line {
        border-bottom: 1px solid #cecfd0;
    }

    .covered, .upgrade {
        margin-left: 10%;

        p {
            font-weight: bold;
            margin: 0 0 10px 35px;
            padding-top: 5px;
        }
    }

    .factoid-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        .blue-circle {
            border-radius: 50%;
            border: 10px solid #113d5d;
            background: #95cfd7;
            height: 210px;
            width: 210px;

            img {
                display: block;
                margin: auto;
                margin-top: 12%;
                width: 75%;
                z-index: 45;
            }
        }

        .blue-rectangle {
            border-radius: 20px;
            background: #113d5d;
            margin-bottom: 20px;
            position: relative;
            text-align: center;
            width: 75%;
            z-index: -1;

            &::before {
                content: "";
                position: absolute;
                top: -50px;
                left: 0;
                background: #113d5d;
                height: 70px;
                width: 100%;
                z-index: -2;
            }

            .factoids {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 100%;

                & > *::after {
                    content: "";
                    border-bottom: 2px solid white;
                    height: 15px;
                    width: 85%;
                }

                .factoid-item {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                    -ms-flex: 0 1 auto;
                    line-height: normal;
                    padding: 10px;
                    width: 100%;


                    .factoid-value {
                        color: #95cfd7;
                        font-weight: bold;
                    }

                    .factoid-title {
                        color: white;
                        font-size: 13px;
                        width: 100%;
                    }
                }
            }
        }
    }

    h1, h2 {
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .legal {
        background: #cecfd0;
        padding: 10px;

        .column {
            flex-direction: row;
            width: 100%;
        }

        .logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 17px;
            width: 275px;
        }

        p {
            font-style: italic;
            margin-bottom: 5px;
            text-align: left;
            word-break: break-word;
        }

        .legal-text {
            padding: 10px;

            .last {
                margin-bottom: 0;
            }
        }
    }

    .limits {
        width: 100%;

        .limits-line-above {
            border-top: 1px solid #cecfd0;
            margin: 15px 0 15px 0;
        }

        .limits-line-below {
            border-bottom: 1px solid #cecfd0;
            margin: -20px 0 10px 0;
        }

        p {
            margin-left: 35px;
        }

        .column {
            margin-bottom: unset;
            width: 100%;
        }
    }

    .limits-exclusions-row {
        display: flex;
        flex-direction: row;

        #download {
            background: #cecfd0;
            height: 150px;
            text-align: center;
            width: 100%;

            button {
                font-size: 17px;
                line-height: 25px;
                padding-left: 0;
                padding-right: 0;
                width: 90%;
                white-space: inherit;
            }

            h1 {
                font-size: 40px;
            }

            h2  {
                font-family: "brandon_grotesquebold", serif;
                margin-top: 20px;
            }

            p {
                color: #113d5d;
                font-size: 12px;
                line-height: 20px;
                margin: 5px 0 10px 0;
            }

            h1, h2 {
                text-align: center;
                text-transform: uppercase;
            }
        }

        .column {
            display: flex;
            flex-direction: column;
        }

        .forth {
            width: 266px;
        }

        p {
            font-size: 13px;
            line-height: 15px;
        }
    }

    .main-title {
        display: flex;
        direction: inherit;
        justify-content: center;
        text-align: center;
        width: 100%;

        .main-title-text {
            h1 {
                font-size: 4vmin;
                &::before {
                    content: "\2014\00b7";
                    margin: 10px;
                }
                &::after {
                    content: "\00b7\2014";
                    margin: 5px;
                }
                &::before, &::after {
                    color: #cecfd0;
                    white-space: pre;
                }
            }
        }
    }

    .not-covered {
        margin-left: 10%;

        p {
            font-weight: bold;
            margin: 0 0 10px 35px;
            padding-top: 5px;
        }
    }

    .plan-upgrade {
        background: #f5a97d;
        border-radius: 45px;
        margin-bottom: 20px;
        padding-bottom: 5%;
        text-align: center;

        .upgrade-img {
            margin: -15px -23px 0 -28px;
        }

        h4 {
            font-size: 14px;
        }
    }

    .upgrade {
        margin-left: 10%;

        p {
            margin: 0 10px 6px 35px;
            padding-top: 5px;
            text-align: left;
        }
    }

    .three-fourths img {
        float: left;
    }

    .vertical-line {
        border-left: 1px solid #cecfd0;
        margin-top: 55px;
    }
}

@include max-screen(1279px) {
    .limits-exclusions {
        width: 100%;

        .column.half, .column.three-fourths, .column.forth {
            margin-bottom: 30px;
            width: unset;
        }

        .factoid-section {

            .blue-circle {
                margin-bottom: 15px;
            }

            .blue-rectangle {
                // margin: 23% 0 0 0;
                height: auto;
                margin-bottom: unset;
                min-width: 312px;
                max-width: 450px;
                padding-bottom: 10px;
                width: 100%;

                &::before {
                    all: unset;
                }

                .factoids {
                    flex-direction: row !important;
                    align-items: baseline;
                    padding-top: 0;

                    & > *::after {
                        all: unset;
                    }

                    & > *:not(:last-child)::after {
                        border-right: 2px solid white;
                        content: "";
                        display: block;
                        height: 70px;
                        margin-left: 16.5%;
                        position: absolute;
                        top: 5px;
                    }

                    .factoid-item {
                        border: none;
                        height: 70px;
                        justify-content: space-between;
                        padding: 0;

                        .factoid-title {
                            font-size: 12px;
                            padding-top: 9px;
                        }

                        .factoid-value {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .limits-exclusions-row {
            flex-direction: column;

            .forth {
                width: unset;
            }
        }
    }
}
