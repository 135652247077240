//
// SVG Icons
// ==========================================================================

// Section Icons
// ==========================================================================
.section-icon {
  @include size(100px);
  @include border-radius(100%);
  @include position(relative, 0);
  background: $gray-one;
  text-align: center;
  display: table;
  @include max-screen($tablet) {
    @include size(70px);
  }
  &:hover {
    @include opacity(1);
  }
  &:before {
    vertical-align: middle;
    display: table-cell;
  }
  &.icon-homeowner {
    font-size: 55px;
    color: $orange;
    @include max-screen($tablet) {
      font-size: 35px;
    }
  }
  &.icon-professional {
    font-size: 56px;
    color: $blue;
    @include max-screen($tablet) {
      font-size: 36px;
    }
  }
  &.icon-contractor {
    font-size: 60px;
    color: $teal;
    @include max-screen($tablet) {
      font-size: 40px;
    }
  }
  &.icon-resources {
    font-size: 60px;
    color: $gray;
    @include max-screen($tablet) {
      font-size: 40px;
    }
  }
}

// Section Icons Inverted
// ==========================================================================
.section-icon-inverted {
  @extend .section-icon;
  background: rgba($white, 0.2);
  color: $white !important;
}