//
// Modal
// ==========================================================================

// Blocker
// ==========================================================================
.layout > .modal,
body > .modal {
  display: none;
}
.blocker {
  @include position(fixed, 0 0 0 0);
  @include size(100%);
  @include padding(40px);
  background: $blue;
  background: rgba($blue, 0.9);
  overflow: auto;
  z-index: $z-above-content;
  box-sizing: border-box;
  text-align: center;
  @include max-screen($smart) {
    @include padding(40px 20px)
  }
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.05em;
  }
  .behind {
    background-color: transparent;
  }
}

// Modal Core
// ==========================================================================
.modal {
  @include border-radius(4px);
  @include margin(0 auto);
  @include padding(20px);
  @include fadeInDown($standard-transition);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: $z-modal;
  max-width: $modal-width;
  background: $white;
  text-align: center;
  .close-modal {
    @include transition(opacity $standard-transition ease);
    @include position(absolute, -35px 20px auto auto);
    @include size(18px 28px);
    overflow: hidden;
    display: block;
    cursor: pointer;
    &:before {
      color: $white;
      font-size: $base-font-size;
      font-family: $icon-font-family;
      content: 'l';
    }
    &:hover {
      @include opacity(0.7);
      outline: none;
    }
  }
}

// Modal Content
// ==========================================================================
.modal {
  .container {
    @include max-screen($smart) {
      width: 100%;
    }
  }
  h3 {
    font-size: 28px;
    color: $blue;
  }
  p {
    @extend p.small;
  }
  fieldset {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 356px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.submit {
      text-align: center;
      button {
        min-width: 245px;
        @include max-screen($smart) {
          min-width: 0;
        }
      }
    }
    &.extra {
      text-align: center;
      p {
        margin-bottom: 0;
      }
    }
  }
  .card-list {
    .card {
      @include margin(0 auto);
      float: none;
      display: block;
    }
  }
  .radio-list {
    margin-left: 25px;
    @include max-screen($smart) {
      @include margin(0);
    }
  }
  .submit-link {
    @include margin(20px auto 0 auto);
    display: block;
    color: $gray;
    font-size: 12px;
    font-family: $sans-font-light;
    text-decoration: underline;
    @include max-screen($smart) {
      @include margin(0);
    }
  }
  .social-link {
    @include clearfix;
    @include margin(0 auto 20px auto);
    display: inline-block;
    text-align: center;
    li {
      @include margin(0 20px 0 0);
      float: left;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
    }
    .facebook {
      @include size(42px);
      @include border-radius(100%);
      font-size: 18px;
      background: $facebook;
      color: $white;
      display: table;
      &:before {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .google {
      @extend .facebook;
      font-size: 24px;
      background: $google;
    }
  }
}

// Modal Content @ Admin
// ==========================================================================
.modal-admin {
  fieldset {
    max-width: none;
    .fieldset-title {
      margin-top: 20px;
    }
  }
  .well {
    @include clearfix;
    @include margin(0 0 20px 0);
    position: relative;
    h4 {
      font-family: $sans-font-medium;
      letter-spacing: 0;
      text-transform: capitalize;
      font-size: 18px;
      .title {
        font-style: italic;
        font-family: $sans-font-light;
        color: $gray;
      }
      @include max-screen($tablet) {
        @include margin(0);
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      @include max-screen($tablet) {
        font-size: 12px;
        line-height: 22px;
      }
    }
    .edit {
      @include border-radius(4px);
      @include padding(6px 12px);
      @include size(auto 24px);
      line-height: 24px;
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $white;
      background: $orange;
    }
  }
  .well-meta {
    @include clearfix;
    @include margin(-10px 0 10px 0);
    @include max-screen($tablet) {
      @include margin(10px 0 0 0);
    }
    li {
      @include margin(0 30px 0 0);
      font-family: $sans-font-light;
      float: left;
      display: inline-block;
      font-size: 14px;
      b {
        font-family: $sans-font-medium;
      }
      a {
        color: $orange;
      }
    }
  }
}


// Modal Content @ Agent Select
// ==========================================================================
.modal-agent {
  fieldset {
    max-width: none;
    .fieldset-title {
      margin-top: 20px;
    }
  }
  .well {
    @include margin(0 0 30px 0);
    padding-right: 80px;
    padding-left: 20px;
    position: relative;
    h4 {
      @include margin(0 0 5px 0);
      font-family: $sans-font-bold;
      text-transform: capitalize;
      letter-spacing: 0;
      font-size: 16px;
      color: $orange;
    }
    p {
      line-height: 20px;
      color: $gray;
      font-family: $sans-font-regular;
    }
    .edit {
      @include position(absolute, auto 20px auto auto);
      @include center(y);
      @include border-radius(4px);
      @include padding(0 12px);
      @include size(auto 24px);
      line-height: 24px;
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $white;
      background: $orange;
      @include max-screen($tablet) {
        right: 20px;
      }
    }
  }
}
