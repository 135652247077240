﻿@charset "UTF-8";
//
// Animations via Keyframes
// ==========================================================================


// Fade In
// ==========================================================================
@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Fade Out
// ==========================================================================
@include keyframes(fade-out) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

// Icon Animation
// ==========================================================================
@include keyframes(icon-fade) {
    0% {
        @include opacity(0);
        @include transform(translate(0, 0));
    }

    50% {
        @include opacity(1);
    }

    100% {
        @include opacity(0);
        @include transform(translate(0, 50px));
    }
}

// Drop-in Animations
// ==========================================================================
@include keyframes(drop-in) {
    0% {
        @include opacity(0);
        @include transform(translateY(-50px));
    }

    100% {
        @include opacity(1);
        @include transform(translateY(0));
    }
}

@include keyframes(drop-in-center) {
    0% {
        @include opacity(0);
        @include transform(translate(-50%,-53%));
    }

    100% {
        @include opacity(1);
        @include transform(translate(-50%,-50%));
    }
}

