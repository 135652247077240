//
// Home
// ==========================================================================

// Global
// ==========================================================================
 main.home {
   // Home specifics here
 }

// Hero
// ==========================================================================
.section-hero {
  @include background-image-cover('../images/bg-hero-homeowner-ghostKitchen.jpg');
  @include size(100% 860px);
  @include padding(200px 0);
  position: relative;
  text-align: center;
  overflow: hidden;
  background-position: bottom;
  // For background-size:cover replacement on iOS devices
  @media only screen and (orientation: portrait) and (device-width: 320px), (device-width: 768px) {
      header {
        background-size: auto 150%;
        -webkit-background-size: auto 150%;
        background-attachment: scroll;
      }
  }
  @media only screen and (orientation: landscape) and (device-width: 320px), (device-width: 768px) {
      header {
        background-size: auto 150%;
        -webkit-background-size: 150% auto;
        background-attachment: scroll;
      }
  }
  @include max-screen($desktop) {
    @include padding(150px 0);
    height: 700px;
  }
  @include max-screen($tablet) {
    @include padding(150px 0 100px 0);
    height: auto;
  }
  @include max-screen($smart) {
    @include padding(110px 0 60px 0);
    background-position: left;
  }
  @include max-screen($mobile) {
    @include padding(110px 0 40px 0);
  }
  @include max-screen-height($ios-height) {
    @include padding(110px 0);
  }
}

// Hero Content
// ==========================================================================
.section-hero {
  .container {
    text-align: left;
  }
  .hero-content {
    @include clearfix;
    max-width: 610px;

  }
  h1 {
    color: $orange;
  }
  p {
    max-width: 600px;
    @include max-screen($tablet) {
      max-width: none;
    }
  }
  .btn-group {
    margin-top: 60px;
    @include max-screen($desktop) {
      margin-top: 30px;
    }
    .btn {
      @include max-screen($tablet) {
        float: left;
        margin-right: 30px;
        display: inline-block;
      }
      @include max-screen($smart) {
        float: none;
        margin-right: 0;
        display: block;
      }
    }
  }
  .scroll-down {
    @include position(absolute, auto auto 60px 0);
    @include center(x);
    @include size(48px);
    @include border-radius(100%);
    display: table;
    vertical-align: middle;
    text-align: center;
    background: $gray-one;
    color: $orange;
    @include max-screen($tablet) {
      bottom: 30px;
    }
    &:before {
      display: table-cell;
      vertical-align: middle;
    }
    @include max-screen($smart) {
      display: none;
    }
  }
}

// Home Logos
// ==========================================================================
.section-logos {
    @include padding(5px 0);

    vertical-align: middle;
    background: $gray-one;

    .item-row {
        display: inline-flex;

        //Centers the items and places space between each item.
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }
    .item {
        width: 100%;

        &.active {
            .bannerimage {
                @include opacity(1);
            }
        }
    }
}

.bannerimage {
    @include opacity(0.3);
}


// Side (Base)
// ==========================================================================
.section-side {
  @include padding(0);
  .container {
    max-width: none;
    width: 100%;
  }
  .column {
    @include max-screen($tablet) {
      @include margin(0);
      float: left;
    }
    @include max-screen($smart) {
      float: none;
    }
  }
  .photo {
    @include size(33.3% 932px);
    max-height: 932px;
    position: relative;
    @include max-screen($smart) {
      @include margin(0);
      width: 100%;
      height: 200px;
    }
    &:after {
      @include position(absolute, 0 0 0 0);
      @include opacity(0.6);
      content: "";
    }
  }
  .content {
    @include padding(100px 5% 40px 5%);
    @include clearfix;
    @include max-screen($tablet) {
      @include padding(5%);
      width: 66.7%;
    }
    @include max-screen($smart) {
      width: 100%;
    }
    p {
      margin-bottom: 0;
      @include max-screen($smart) {
        margin-bottom: 20px;
      }
    }
  }
  .btn-group {
    @include margin(100px 0);
    @include max-screen($desktop) {
      @include margin(70px 0);
    }
    @include max-screen($tablet) {
      @include margin(30px 0);
    }
    @include max-screen($smart) {
      @include margin(0 0 20px 0);
    }
    .btn {
      @include max-screen($tablet) {
        @include padding(0 20px);
      }
      i {
        @include max-screen($tablet) {
          display: none;
        }
      }
    }
  }
  h4 {
    @include max-screen($smart) {
      text-align: center;
    }
  }
  .feature-list {
    li {
      @include margin(0 0 7px 0);
      color: $gray-two;
      font-size: $base-font-size - 2;
      @include max-screen($tablet) {
        font-size: $base-font-size - 4;
        margin-bottom: 0;
      }
      @include max-screen($smart) {
        text-align: center;
      }
    }
  }
}

// Side (Professional)
// ==========================================================================
.section-side.professional {
  .photo {
    @include background-image-cover('../images/bg-side-professional.jpg');
    @include max-screen($smart) {
      background-position: center 54%;
    }
    &:after {
      background: $blue;
    }
  }
}

// Side (Contractor)
// ==========================================================================
.section-side.contractor {
  background: $gray-zero;
  h1,
  h4 {
    color: $teal;
  }
  .photo {
    @include background-image-cover('../images/bg-side-contractor.jpg');
    @include max-screen($smart) {
      background-position: center 54%;
    }
    &:after {
      background: $teal;
    }
  }
}

// Resources (Intro)
// ==========================================================================
.section-resources-intro {
  @include background-image-cover('../images/bg-hero-resources.jpg');
  @include size(100% auto);
  @include padding(90px 0 60px 0);
  position: relative;
  text-align: center;
  @include max-screen($tablet) {
    @include padding(60px 0);
    background-position: initial;
  }
  @include max-screen($tablet) {
    @include padding(30px 0);
  }
  .section-icon {
    @include margin(0 auto);
  }
  h1 {
    color: $white;
  }
  .intro-text {
    color: $white;
    font-size: $base-font-size - 2;
    line-height: 22px;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper {
    @include margin(0 auto 60px auto);
    @include box-shadow(0, 30px, 40px, 0, rgba($black, 0.10));
    @include size(100% auto);
    @include border-radius(6px);
    @include padding(30px);
    text-align: left;
    background: $white;
    @include max-screen($smart) {
      @include padding(20px 10px);
      margin-bottom: 50px;
    }
    .column {
      @include box-shadow(-1px, 0, 0, 0, $gray-one, true);
      @include max-screen($tablet) {
        @include box-shadow(0, 0, 0, 0, transparent);
        border-bottom: 1px solid $gray-one;
        padding-bottom: 30px;
      }
      @include max-screen($smart) {
        padding-bottom: 20px;
      }
      &:last-child {
        @include box-shadow(0, 0, 0, 0, transparent);
        @include max-screen($tablet) {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
    h3 {
      margin-top: 10px;
      margin-bottom: 30px;
      @include max-screen($tablet) {
        margin-bottom: 15px;
      }
    }
    p {
      color: $gray;
    }
    .date {
      color: $gray-two;
    }
    .read-more,
    .date {
      font-size: 12px;
      font-family: $sans-font-bold;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      @include max-screen($tablet) {
        font-size: 10px;
      }
    }
    .read-more{
        font-weight:bold;
    }
  }
}

// Reviews
// ==========================================================================
.section-reviews {
  background: $white;
  text-align: center;
  h1 {
    color: $orange;
  }
  .slider-nav {
    @include clearfix;
    @include margin(0 auto);
    text-align: center;
    li {
      @include margin(0 3% 0 0);
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        a {
          @include opacity(1);
        }
      }
      a {
        @include opacity(0.5);
        @include size(42px);
        @include border-radius(100%);
        overflow: hidden;
        display: block;
        @include max-screen($smart) {
          @include size(30px);
        }
        &:hover {
          @include opacity(1);
        }
      }
    }
  }
  .slider-content {
    @include clearfix;
    text-align: center;
    display: inline-block;
    li {
      float: left;
    }
    figure {
      @include size(160px);
      @include margin(20px auto);
      position: relative;
      display: block;
      img {
        @include border-radius(100%);
        width: 100%;
        display: block;
      }
    }
    .name {
      font-size: 14px;
      font-family: $sans-font-bold;
      letter-spacing: 1.2px;
      color: $blue;
      text-transform: uppercase;
    }
    .meta {
      @extend .name;
      @include margin(0 auto 30px auto);
      color: $gray-two;
      font-size: 12px;
      @include max-screen($smart) {
        margin-bottom: 20px;
      }
    }
    p {
      @include margin(0 auto);
      max-width: 720px;
    }
    .prev {
      @include position(absolute, 0 auto auto -60px);
      @include center(y);
      @include max-screen($smart) {
        left: -40px;
      }
    }
    .next {
      @extend .prev;
      right: -60px;
      left: auto;
      @include max-screen($smart) {
        right: -40px;
      }
    }
  }
}

// Awards
// ==========================================================================
.section-awards {
    background: $white;
    text-align: center;
    h3 {
      color: $blue;
    }
    .slider-nav {
      @include clearfix;
      @include margin(0 auto);
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding: 0 10%;
      justify-content: center;
      text-align: center;
      li {
        @include margin(0 3% 0 0);
        display: inline-block;
        flex-basis: 12%;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          a {
            @include opacity(1);
          }
        }
        a {
          @include opacity(0.5);
          display: block;
          overflow: hidden;
          @include max-screen($smart) {
            width: 30px;
          }
          &:hover {
            @include opacity(1);
          }
        }
      }
    }
    .slider-content {
      @include clearfix;
      display: inline-block;
      text-align: center;

      li {
        float: left;
      }
      figure {
        @include size(160px);
        @include margin(auto);
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        position: relative;

        img {
            display: block;
            flex-basis: 40%;
            width: 100%;
        }
      }
      .name {
        color: $blue;
        font-family: $sans-font-bold;
        font-size: 14px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
      .meta {
        @extend .name;
        @include margin(0 auto 30px auto);
        color: $gray-two;
        font-size: 12px;
        @include max-screen($smart) {
          margin-bottom: 20px;
        }
      }
      p {
        @include margin(0 auto);
        max-width: 720px;
      }
      .prev {
        @include position(absolute, 0 auto auto -60px);
        @include center(y);
        @include max-screen($smart) {
          left: -40px;
        }
      }
      .next {
        left: auto;
        @extend .prev;
        right: -60px;
        @include max-screen($smart) {
        right: -40px;
        }
      }
    }
  }

// CTA
// ==========================================================================
.section-cta {
  background: $blue;
  h1 {
    color: $white;
    text-align: center;
  }
  p {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    color: $white;
    color: rgba($white, 0.6);
  }
  fieldset {
    @include margin(30px auto 45px auto);
    @include max-screen($smart) {
      @include margin(20px auto);
    }
  }
  .field-label {
    font-size: 12px;
    color: $gray-two;
    letter-spacing: 1.8px;
    margin-left: 20px;
    @include max-screen($tablet) {
      font-size: 10px;
      letter-spacing: 1px;
      margin-left: 0;
    }
  }
  input,
  #{$all-text-inputs} {
    background: rgba($white, 0.1);
    border-color: transparent;
    color: $white;
    &.error {
        background: #FEECEE;
        @include placeholder {
          color: $red;
        }
        color: $red;
      }
  }
  input:focus,
  #{$all-text-inputs-focus} {
    border-color: transparent;
    background: rgba($teal, 0.5);
  }
  select {
      background: rgba($white, 0.1);
      border-color: transparent;
      border-radius: 4px;
      color: $white;
      font-size: 14px;
      padding-left: 15px;
  }
  select:focus,
  select:active {
      border-color: transparent;
      background: rgba($teal, 0.5);
      color: #333333;
  }
}
