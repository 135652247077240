//
// Buttons
// ==========================================================================

// Base Style (Black)
// ==========================================================================
.btn,
#{$all-buttons} {
  @include transition(all $quick-transition ease);
  @include margin(0);
  @include padding(0 40px);
  @include position(relative, null);
  @include appearance(none);
  @include opacity(1);
  @include border-radius(6px);
  @include box-shadow(0, 4px, 0, 0, $blue-dark);
  font-size: $base-font-size - 6;
  font-family: $sans-font-bold;
  line-height: $btn-height;
  letter-spacing: 1.8px;
  background: $blue;
  color: $white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  // Responsive
  @include max-screen($tablet) {
    @include padding(0 30px);
    line-height: $btn-height - 8;
    font-size: $base-font-size - 8;
  }
  @include max-screen($smart) {
    @include padding(0 20px);
    line-height: $btn-height - 10;
    width: 100%;
    margin-bottom: 20px;
    display: block;
    float: none;
  }
  @include max-screen($mobile) {
    white-space: normal;
  }
  // Hover State
  &:hover {
    //@include opacity(0.7);
    text-decoration: none;
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 4px, 0, 0, $blue);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $blue;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover{
        background: $gray-two;
        color: $gray;
        cursor: not-allowed;
        @include box-shadow(0, 4px, 0, 0, $gray-dark);
    }

    background: $gray-two;
    color: $gray;
    cursor: not-allowed;
    @include box-shadow(0, 4px, 0, 0, $gray-dark);
  }
  &.disabled {
    pointer-events: none;
   }
}

// Button Groups
// ==========================================================================
.btn-group {
  @include clearfix;
  @include max-screen($tablet) {
    float: none;
    display: block;
  }
  .btn {
    float: left;
    margin-right: 30px;
    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
    @include max-screen($tablet) {
      display: block;
      margin-right: 0;
      margin-bottom: 20px;
      float: none;
    }
  }
}

// Button Icons
// ==========================================================================
.btn,
.btn-small,
.btn-huge {
  &[data-icon]:before {
    margin-left: 10px;
    position: relative;
    top: 4px;
  }
  i {
    font-size: $base-font-size - 4;
    pointer-events: none;
    margin-left: 20px;
    &:before {
      vertical-align: middle;
    }
    @include max-screen($tablet) {
      margin-left: 10px;
    }
  }
}

// Alternate Sizes
// ==========================================================================
.btn-small {
  @include padding(0 15px);
  font-size: $base-font-size - 2;
  line-height: $btn-small-height;
  @include max-screen($smart) {
    font-size: $base-font-size - 3;
    font-family: $sans-font-regular;
    line-height: $btn-small-height - 5;
  }
}
.btn-huge {
  @include padding(0 60px);
  font-size: $base-font-size + 4;
  line-height: $btn-huge-height;
  @include max-screen($tablet) {
    @include padding(0 40px);
    font-size: $base-font-size;
    line-height: $btn-huge-height - 4;
  }
  @include max-screen($smart) {
    font-size: $base-font-size;
    font-family: $sans-font-regular;
    line-height: $btn-huge-height - 10;
  }
}
.btn-block {
  display: block;
  text-align: center;
  width: 100%;
}
.btn-input {
  height: 37px;
  line-height: 37px;
  @include max-screen($tablet) {
    height: 33px;
    line-height: 33px;
  }
  @include max-screen($smart) {
    height: 34px;
    line-height: 34px;
  }
}

// Alternate Styles
// ==========================================================================
.btn-transparent {
  @extend .btn;
  background: transparent;
  color: inherit;
  &:active,
  &.active,
  &:focus,
  &.focus {
    background: transparent;
    color: $green;
    @include opacity(1);
  }
}

// Alternate Colors (default)
// ==========================================================================
.btn-orange {
  @extend .btn;
  @include box-shadow(0, 4px, 0, 0, $orange-dark);
  background: $orange;
  padding-top: 2px;
  // Focus State
  &:focus,
  &.focus {
    background: $orange;
    color: $white;
    outline: 0;
  }
  &:hover,
  &.hover {
      background: $orange-light;
      color: $white;
      outline: 0;
      @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 4px, 0, 0, $orange);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
}
.btn-teal {
  @extend .btn;
  @include box-shadow(0, 4px, 0, 0, $teal-dark);
  background: $teal;
  // Focus State
  &:focus,
  &.focus {
    background: $teal;
    color: $white;
    outline: 0;
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 4px, 0, 0, $teal);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
}
.btn-gray {
  @extend .btn;
  @include box-shadow(0, 4px, 0, 0, $gray-dark);
  background: $gray;
  // Focus State
  &:focus,
  &.focus {
    background: $gray;
    color: $white;
    outline: 0;
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 4px, 0, 0, $gray);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
}
.btn-green {
    @extend .btn;
    @include box-shadow(0, 4px, 0, 0, $seaGreen);
    background: $green;
    padding-top: 2px;
    // Focus State
    &:focus,
    &.focus {
      background: $green;
      color: $white;
      outline: 0;
    }
    &:hover,
    &.hover {
        background: $green-light;
        color: $white;
        outline: 0;
    }
}
.btn-white {
  @extend .btn;
  @include box-shadow(0, 0, 0, 0, $white);
  background: $white;
  // Focus State
  &:focus,
  &.focus {
    background: $white;
    color: inherit;
    outline: 0;
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 0, 0, 0, $white);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
}

// Strokes
// ==========================================================================
.btn-stroke {
  @include box-shadow(0, 2px, 0, 2px, $blue, true);
  color: $gray;
  background: $white;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $blue;
    color: $white;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $gray;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
    }
  &.disabled {
    pointer-events: none;
  }
}
.btn-stroke-orange {
  @include box-shadow(0, 0, 0, 2px, $orange, true);
  color: $orange;
  background: $white;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $orange;
    color: $white;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $orange;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
  }
  &.disabled {
    pointer-events: none;
  }
}
.btn-stroke-green {
  @include box-shadow(0, 0, 0, 2px, $green, true);
  color: $green;
  background: $white;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $green;
    color: $white;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $green;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
  }
  &.disabled {
    pointer-events: none;
  }
}
.btn-stroke-gray {
  @include box-shadow(0, 0, 0, 2px, $gray, true);
  border-bottom: 2px solid $gray;
  color: $blue;
  background: $white;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $gray;
    color: $white;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 0, 0, 2px, $gray, true);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $gray;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
  }
  &.disabled {
    pointer-events: none;
  }
}
.btn-stroke-light {
  @include box-shadow(0, 0, 0, 2px, $gray-two, true);
  border-bottom: 2px solid $gray-two;
  color: $blue;
  background: $white;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $gray-two;
    color: $white;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 0, 0, 2px, $gray-two, true);
    @include opacity(1);
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $gray-two;
    color: $white;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
  }
  &.disabled {
    pointer-events: none;
  }
}
.btn-stroke-white {
  @include box-shadow(0, 0, 0, 2px, $white, true);
  color: $white;
  background: transparent;
  @include max-screen($tablet) {
    line-height: $btn-height - 5px;
  }
  @include max-screen($smart) {
    line-height: $btn-height - 7px;
  }
  // Hover State
  &:hover {
    background: $white;
    color: $gray;
    @include opacity(1);
  }
  // Active State
  &:active,
  &.active {
    @include box-shadow(0, 0, 0, 2px, $white, true);
    @include opacity(1);
    color: $gray-one;
    outline: 0;
    background-image: none;
  }
  // Focus State
  &:focus,
  &.focus {
    background: $white;
    color: $gray-one;
    color: initial;
    outline: 0;
  }
  // Disabled State
  &.disabled,
  &[disabled] {
    @include box-shadow(0, 0, 0, 2px, $gray-one, true);
    background: $gray-zero;
    color: $gray-one;
    cursor: not-allowed;
  }
  &.disabled {
    pointer-events: none;
  }
}

// Button Colors
// ==========================================================================
.btn-homeowner { color: $orange; padding: 4px 40px 0px 40px; }
.btn-professional { color: $blue; padding-top:2px; }
.btn-contractor { color: $teal; padding-top:2px;}
.btn-resources { color: $gray;  padding-top:2px;}

// Button Links
// ==========================================================================
.btn-link {
  @include border-radius(4px);
  @include padding(16px 15px 15px 15px);
  border: 2px dashed $gray-one;
  color: $blue;
  font-size: $base-font-size - 2;
  line-height: initial;
  width: 100%;
  outline: none;
  text-align: left;
  border-style: dashed;
  background: $gray-zero;
  color: $blue;
}

.toggleCircle {
    border-radius: 50%;
    background: $gray-two;

    &:hover {
        cursor: pointer;
    }

    &.active {
        background: $green;
        color: $white;
    }
}

.btn-link2 {
    background: none;
	box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
	color: #1F506F;
	font: inherit;
    letter-spacing: normal;
    padding: 0;
	text-transform: none;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }

    &:focus {
        background: none;
        color: #1F506F;
    }
}
