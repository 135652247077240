@-webkit-keyframes slideInDown {
	0% { -webkit-transform: translateX(2000px); opacity: 0;}
	100% { -webkit-transform: translateX(0);}
}

@-moz-keyframes slideInDown {
	0% { -moz-transform: translateX(2000px); opacity: 0;}
	100% { -moz-transform: translateX(0);}
}

@-ms-keyframes slideInDown {
	0% { -ms-transform: translateX(2000px); opacity: 0;}
	100% { -ms-transform: translateX(0);}
}

@-o-keyframes slideInDown {
	0% { -o-transform: translateX(2000px); opacity: 0;}
	100% { -o-transform: translateX(0);}
}

@keyframes slideInDown {
	0% { transform: translateX(2000px); opacity: 0;}
	100% { transform: translateX(0);}
}

@mixin slideInDown($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(slideInDown);
	@include function(ease-in);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.slideInDown { @include slideInDown(); }