.section-logos {
    .best-regional {
        margin-left: -45px;
    }

    .consumer-affairs {
        margin-left: 61px;
        width: 88px;
    }

    .inc5000 {
        height: 86px;
        margin-left: 17px;
        width: 119px;
    }

    .national-home-service {
        height: 126px;
    }

    .bbb {
        height: unset;
        width: unset;
    }

    .container {
        margin-left: 117px;
    }
}


@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px) {
    .section-logos {
        .best-regional {
            height: unset;
            margin-left: unset;
            width: unset;
        }

        .consumer-affairs {
            height: 100px;
            margin-left: 61px;
            width: unset;
        }

        .inc5000 {
            height: 86px;
            margin-left: 27px;
            width: 119px;
        }

        .national-home-service {
            height: 115px;
            margin-left: 23px;
            width: 115px;
        }

        .bbb {
            height: unset;
            width: unset;
        }

        .container {
            margin-left: 45px;
        }
    }
}


@media only screen
and (min-device-width : 768px)
and (max-device-width : 1023px) {
    .section-logos {
        .best-regional {
            height: unset;
            margin-left: unset;
            width: unset;
        }

        .consumer-affairs {
            height: 91px;
            margin-left: 36px;
            width: 77px;
        }

        .inc5000 {
            height: 86px;
            margin-left: 10px;
            width: 119px;
        }

        .national-home-service {
            height: 100px;
            margin-left: 15px;
            width: 100px
        }

        .bbb {
            height: unset;
            width: unset;
        }

        .container {
            margin-left: 33px;
        }
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 767px)  {
    .section-logos {
        .best-regional {
            margin-left: unset;
        }

        .consumer-affairs {
            height: 50px;
            margin-left: 5px;
            width: 43px;
        }

        .inc5000 {
            height: unset;
            margin-left: unset;
            width: unset;
        }

        .national-home-service {
            height: 55px;
            width: 50px
        }

        .bbb {
            height: 22px;
            width: unset;
        }

        .container {
            margin-left: 20px;
        }

        .item {
            padding-right: 10px;
        }
    }
}





