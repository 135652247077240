//
// Error
// ==========================================================================

// Main @ Error
// ==========================================================================
main.error {
  .section-intro {
    @include margin(0 0 -240px 0);
    @include padding(0);
    display: table;
    height: 100vh;
    @include max-screen($desktop) {
      margin-bottom: -345px;
    }
    @include max-screen($tablet) {
      margin-bottom: -285px;
    }
    @include max-screen($smart) {
      margin-bottom: 0;
    }
    .container {
      width: 100%;
      display: table-cell;
      vertical-align: middle;
    }
    .intro-content {
      @include margin(0 auto);
      width: 90%;
    }
    h1 {
      font-size: 42px;
      line-height: 47px;
      margin-bottom: 10px;
      @include max-screen($desktop) {
        font-size: 32px;
        line-height: 42px;
      }
      @include max-screen($tablet) {
        @include margin(20px 0)
      }
      @include max-screen($smart) {
        @include margin(10px 0);
        font-size: 24px;
        line-height: 32px;
      }
    }
    p {
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $gray-three;
      letter-spacing: 1.8px;
    }
    .btn-back {
      i {
        @include margin(0 10px 0 0);
      }
    }
  }
}
