@import "../../../content/css/modules/media-queries";

.social-share {
    color: #203E5A;
    font-size: .75em;
    line-height: 1;
    text-align: center;

    .btn-orange {
        padding: 0;
        width: 85%;

        i {
            margin-left: 5%;
        }

        @include max-screen-height(900px) {
            line-height: 3;
            margin: 0;
        }
    }

    /* Use flexbox when CSS-Grid is not available. */
    .social-share-button-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        a {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            flex: 1 1 25%;
            margin: 2%
        }

        /* If CSS-Grid is available(which it is for everything but IE 11), use CSS-Grid. */
        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            padding: 0 10%;
        }
    }

    .social-share-form {
        .field-validation-error {
            bottom: 3em;
            position: relative;

            @include max-screen-height(900px) {
                bottom: 2em;
            }
        }

        input::placeholder {
            color: #203E5A;
        }

        input, select {
            color: #203E5A;
            height: unset !important;
            margin: 2.5% 2.5% 5% 2.5%;
            padding: 4%;
            width: 95% !important;

            // Styling for when the
            &:not(.error) {
                background-color: #BBE0E8;
                border: 1px solid transparent;
            }

            @include max-screen-height(900px) {
                margin: 2.5% 2.5% 2.5% 2.5%;
                padding: 2% 4%;
            }
        }

        label {
            font-size: 1em;

            &.subtitle {
                font-size: .75em;
            }

            @include max-screen-height(900px) {
                font-size: .75em;

                &.subtitle {
                    font-size: .65em;
                }
            }
        }

        .title {
            margin-bottom: 10%;

            @include max-screen-height(900px) {
                margin-bottom: 0;
            }
        }
    }

    .title {
        font-size: 2.5em;
        font-weight: 600;

        @include max-screen-height(900px) {
            font-size: 1.5em !important;
        }
    }

    .subtitle {
        font-size: .75em;
    }
}

/* For some reason the button won't react to bottom and right, so calc the left and top. */
.social-share-dialog-button {
    background-color: #95D0D8;
    border-radius: 50%;
    height: 66px;
    left: calc(100% - 76px);
    pointer-events: fill;
    position: absolute;
    top: calc(100% - 150px);
    width: 66px;

    /* If position: sticky is available(which it is for everything but IE 11), use position: sticky. */
    @supports (position: sticky) {
        position: sticky;
    }
}

.social-share-side-panel {

    /* If position: sticky is available(which it is for everything but IE 11), use position: sticky. */
    @supports (position: sticky) {
        position: sticky;
        top: 12.5%;
    }
}
