//
// Plans
// ==========================================================================

// Plans @ Homeowner, Professional
// ==========================================================================
.section-plans {
    padding: 0 0;

    fieldset {
        @include margin(0);
    }

    background-color: rgb(251, 251, 251);

    .column {
        // Use percentages so the padding is more responsive.
        padding: 0 1% 0 1% !important;

        &.selected {
            @include border-radius(6px);
            @include box-shadow(0, 0, 0, 2px, $orange, true);
            background: rgba($orange, 0.04);
            border: none;

            &:after {
                @include position(absolute, 30px 30px auto auto);
                @include size(40px);
                font-family: $icon-font-family;
                font-size: 40px;
                content: "X";
                color: $orange;
            }
        }

        @include max-screen($tablet) {
            padding-top: 1em;
        }
    }

    .border {
        > .column {
            border-right: 1px solid #F2693E;

            @include max-screen($tablet) {
                &:nth-child(2) {
                    border-right: none;
                }
            }

            @include max-screen($smart) {
                border-bottom: 1px solid $gray-two;
                border-right: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}

// Plans Quick Order
// ==========================================================================
.pricing-quick-order {
    @include border-radius(6px);
    @include margin(0 0 0 0);
    @include padding(40px);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background: $gray-zero;
    // border: 1px solid $gray-one;

    @include max-screen($desktop) {
        @include padding(30px);
    }

    @include max-screen($tablet) {
        @include padding(20px);
    }

    @include max-screen($smart) {
        @include padding(10px);
    }

    h3 {
        margin-bottom: 35px;

        @include max-screen($tablet) {
            padding-left: 1em;
        }

        @include max-screen($smart) {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 15px;
            padding-left: 0;
        }
    }

    .btn {
        display: block;
    }

    input,
    #{$all-text-inputs},
    .select select {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .field-label {
        color: $gray-two;
        letter-spacing: 1.8px;

        @include max-screen($tablet) {
            font-size: 10px;
            letter-spacing: 1px;
            margin-left: 0;
        }
    }
}

// Plans Details
// ==========================================================================
.pricing-copy {
    @include margin(0 auto 30px auto);
    text-align: center;
    max-width: 600px;

    .select {
        @include margin(0 auto);
        max-width: 230px;
    }
}

.pricing-title {
    @include margin(20px 0 60px 0);
    position: relative;

    @include max-screen($tablet) {
        margin-bottom: 30px;
        padding-top: 10px;
    }

    .container {
        @include max-screen($desktop) {
            width: 100%;
        }
    }

    .price-container {
        // View width units should grow/shrink as the screen size changes.
        font-size: 2.5vw;
        margin-bottom: 0;

        @include max-screen($tablet) {
            text-align: center;
        }
    }

    .price-tag {
        @include margin(20px 0 0 25%);
        font-family: $sans-font-bold;
        font-weight: bold;
        display: inline-block;
        position: relative;
        float: none !important;

        &.white {
            &:before,
            &:after {
                color: $orange-light;
            }
        }

        @include max-screen($tablet) {
            margin: 0;
            font-size: 36px;
        }

        &:before {
            // position uses percentages to be responsive as the text grows bigger.
            @include position(absolute, -22% auto auto -15%);
            content: "$";
            display: block;
            color: $gray-two;
            font-family: $sans-font-light;
            font-size: 35%;
        }
    }

    .credit-tag {
        @include position(absolute, 0 0 auto auto);
        @include size(74px);
        font-size: 27px;
        line-height: 58px;

        @include max-screen($desktop) {
            @include size(50px);
            font-size: 18px;
            line-height: 38px;
        }

        span {
            font-size: 10px;
            top: -14px;

            @include max-screen($desktop) {
                top: -9px;
                font-size: 8px;
            }
        }
    }

    i.icon {
        display: block;
        font-size: 2em;
    }

    h3 {
        @include padding("0 5%");
        font-family: $serif-subfont-regular;
        font-size: 2.5vw;
        line-height: 51px;
        text-transform: uppercase;
        letter-spacing: 2px;

        // Default the font size to 30px when the screen is full size or tablet size or smaller.
        // Otherwise the text will grow/shrink as the screen size is changed.
        @include min-screen($desktop+10em) {
            font-size: 30px;
        }

        @include max-screen($tablet) {
            font-size: 30px;
        }

        @include max-screen($desktop) {
            @include margin(0 0 20px 0);
        }
    }

    .btn-stroke-light {
        @include max-screen($wide) {
            @include padding(0 30px);
        }

        @include max-screen($desktop) {
            @include padding(0 15px);
            letter-spacing: 1px;
            font-size: 11px;
            display: block;
        }

        i {
            @include max-screen($desktop) {
                display: none;
            }

            @include max-screen($tablet) {
                display: inline-block;
            }
        }
    }

    .standard {
        color: $standard;
    }

    .premier {
        color: $premier;
    }

    .value {
        color: $value;
    }

    .deluxe {
        color: $deluxe;
    }

    .white {
        color: $white;
    }
}

.pricing-details {
    margin-bottom: 20px;
    text-align: left;

    .container {
        @include max-screen($desktop) {
            width: 100%;
        }

        .column {
            @include max-screen($tablet) {
                @include padding(0 !important);
                float: none;
                width: 100%;
            }
        }
    }

    dl {
        @include margin(0 0 30px 0);

        &:last-child {
            @include margin(0);
        }

        > dt {
            @include margin(0 0 10px 0);
            @include padding(0 0 10px 0);
            font-family: $sans-font-medium;
            line-height: initial;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            color: $gray-three;
            border-bottom: 1px solid $gray-one;
        }

        > dd {
            @include clearfix;
            font-family: $sans-font-medium;
            font-size: 14px;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include max-screen($smart) {
                white-space: normal;
                overflow: visible;
            }

            &.wrap {
                white-space: normal;
                overflow: visible;
            }

            .cost {
                color: $orange;
            }

            .divider {
                @include margin(0 3px);
                color: $gray-one;
            }

            .item {
                font-family: $sans-font-regular;
                color: $gray;
            }

            .desc {
                @include margin(0 0 20px 25px);
                display: block;
                font-size: 10px;
                line-height: 16px;
                font-style: italic;
                color: $gray-two;
            }

            .status {
                @include size(13px auto);
                @include margin(0 10px 0 0);
                float: left;
                text-align: center;
                vertical-align: middle;

                &[data-icon="C"]:before {
                    color: $green;
                    font-size: 13px;
                }

                &[data-icon="B"]:before {
                    color: $red;
                    font-size: 9px;
                }
            }
        }
    }
}

// Section Plans More
// ==========================================================================
.section-plans-more {
    background: $gray-zero;
    border-top: 1px solid $gray-one;
    border-bottom: 1px solid $gray-one;

    .pricing-title {
        text-align: center;
    }

    .container {
        max-width: 643px;
    }

    .border {
        .column {
            border-right: 1px solid $gray-two;

            @include max-screen($tablet) {
                border-right: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    dl > dd {
        @include clearfix;
        font-family: $sans-font-regular;
        border-bottom: 1px solid $gray-one;
        line-height: 36px;

        .item {
            font-size: 14px;

            @include max-screen($smart) {
                font-size: 12px;
            }
        }

        .status,
        .cost {
            float: right;
        }

        .cost {
            font-family: $sans-font-bold;
            font-size: 14px;
        }
    }
}

// Credit Tag
// ==========================================================================
.credit-tag {
    @include size(50px);
    @include border-radius(100%);
    @include margin(0 0 0 20px);
    vertical-align: middle;
    color: $white;
    font-family: $sans-font-bold;
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
    line-height: 38px;
    background: $green;
    display: inline-block;
    text-align: center;

    @include max-screen($smart) {
        @include margin(15px auto);
        display: block;
    }

    span {
        @include position(relative, -9px auto auto auto);
        display: block;
        line-height: initial;
        text-transform: uppercase;
        font-size: 8px;
    }
}

.credit-tag-deluxe {
    @extend .credit-tag;
    background: $deluxe;
}
