//
// Tooltips (https://github.com/dmtintner/scss-tooltips)
// ==========================================================================

// SASS Tooltips Core & Mixin Import
// ==========================================================================
//
// SCSS Tooltips
// By David Tintner & Hacking UI
// http://hackingui.com/front-end/scss-tooltips/
//
// $direction: ne, nw, se, sw. relative to the element the tooltip is on
// $bg-color: any CSS valid color string, will be applied as bg color of tooltip
// Content for tooltip comes from data-tooltip attribute on element
//

// Dependencies
// @import '../bower_components/UtilityBelt/utility-belt';

// Dependencies
// Triangle
// @include triangle within a pseudo element and add positioning properties (ie. top, left)
// $direction: up, down, left, right
//
@mixin triangle($direction, $size: 8px, $color: $blue) {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  @if ($direction == 'up') {
      border-bottom: $size solid $color;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: none;
  } @else if ($direction == 'down') {
      border-top: $size solid $color;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: none;
  } @else if ($direction == 'left') {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-right: $size solid $color;
      border-left: none;
  } @else if ($direction == 'right') {
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;
      border-left: $size solid $color;
      border-right: none;
  }
}

// Mixin
@mixin tooltip-position($direction) {
  $positionX: "";
  $positionY: "";
  $translateX: "";
  $translateY: "";
  @if $direction == 'ne' {
    $positionX: left;
    $positionY: top;
    $translateX: 0;
    $translateY: -100%;
  } @else if $direction == 'n' {
    $positionX: left;
    $positionY: top;
    $translateX: -50%;
    $translateY: -100%;
  } @else if $direction == 'nw' {
    $positionX: right;
    $positionY: top;
    $translateX: 0;
    $translateY: -100%;
  } @else if $direction == 'w' {
    $positionX: left;
    $positionY: top;
    $translateX: -100%;
    $translateY: -50%;
  } @else if $direction == 'e' {
    $positionX: right;
    $positionY: top;
    $translateX: 100%;
    $translateY: -50%;
  } @else if $direction == 'sw' {
    $positionX: right;
    $positionY: bottom;
    $translateX: 0;
    $translateY: 100%;
  } @else if $direction == 's' {
    $positionX: left;
    $positionY: bottom;
    $translateX: -50%;
    $translateY: 100%;
  } @else if $direction == 'se'{
    $positionX: left;
    $positionY: bottom;
    $translateX: 0;
    $translateY: 100%;
  }

  // Utility variables
  $tooltip-body: '&:before';
  $tooltip-arrow: '&:after';

  // Config variables
  $tooltip-proximity: 3px;
  $tooltip-triangle-height: 6px !default;
  $tooltip-triangle-width: $tooltip-triangle-height * 2;
  $positionXValue: 0;
  $positionYValue: -($tooltip-triangle-height + $tooltip-proximity);
  $trianglePositionXValue: 50%;
  $trianglePositionYValue: $positionYValue;
  $triangleDirection: down;

  #{$tooltip-arrow} {
    transform: translate3d(-50%, 0, 0);
    @if ($positionX == 'right') {
      transform: translate3d(50%, 0, 0);
    }
  }
  // North direction
  @if ($translateX == -50%) {
    $positionXValue: 50%;
  }
  // East-West direction
  @if ($translateY == -50%) {
    $positionXValue: -($tooltip-triangle-height + $tooltip-proximity);
    $positionYValue: 50%;
    $trianglePositionXValue: -$tooltip-proximity;
    $trianglePositionYValue: 50%;
    $triangleDirection: left;
    @if ($translateX == -100%) {
      $triangleDirection: right;
    }
    #{$tooltip-arrow} {
      transform: translate3d(#{$translateX}, #{$translateY}, 0);
    }
  }

  // South direction
  @if ($translateY == 100%) {
    $triangleDirection: up;
  }
  $positionX-override: 'right';
  @if ($positionX == 'right') {
    $positionX-override: 'left';
  }
  $positionY-override: 'bottom';
  @if ($positionY == 'bottom') {
    $positionY-override: 'top';
  }
  #{$tooltip-body} {
    #{$positionY}: $positionYValue;
    #{$positionY-override}: auto;
    #{$positionX}: $positionXValue;
    #{$positionX-override}: auto;
    transform: translate3d(#{$translateX}, #{$translateY}, 0);
  }
  #{$tooltip-arrow} {
    @include triangle($triangleDirection, $tooltip-triangle-height, currentColor);
    #{$positionY}: $trianglePositionYValue;
    #{$positionY-override}: auto;
    #{$positionX}: $trianglePositionXValue;
    #{$positionX-override}: auto;
  }
}
$tooltip-font-color-default : white !default;

// Tooltip Styles
// ==========================================================================
@mixin tooltip( $tooltip-data-attribute: 'data-tooltip',
                $tooltip-min-width: 200px,
                $tooltip-background-color: rgba($blue, 0.95),
                $tooltip-font-color: $tooltip-font-color-default,
                $fade-out-duration: $quick-transition,
                $fade-in-delay: $quick-transition,
                $tooltip-padding: 10px 15px ) {
  @if lightness($tooltip-background-color) > 50% and not($tooltip-font-color == $tooltip-font-color-default) {
    $tooltip-font-color: black;
  }
  position: relative; // can override this to absolute or fixed if necessary. Just can't be static
  &:before,
  &:after {
    @include opacity(0);
    z-index: $z-above-content + 1;
    transition: opacity $fade-out-duration, visibility 0ms linear $fade-out-duration; // delay visibility on fade out
    pointer-events: none;
    @include max-screen($smart) {
      display: none;
    }
  }
  &:before {
    // Tooltip body
    @include border-radius(4px);
    content: attr(#{$tooltip-data-attribute});
    position: absolute;
    min-width: $tooltip-min-width;
    padding: $tooltip-padding;
    line-height: 17px;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    font-family: $sans-font-medium;
    white-space: normal;
    background-color: $tooltip-background-color;
    color: $tooltip-font-color;
  }
  &:after {
    // Tooltip carrot
    content: '';
    color: $tooltip-background-color;
  }
  &:hover {
    &:before,
    &:after {
      @include opacity(1);
      pointer-events: all;
      transition-delay: $fade-in-delay;
      @include max-screen($smart) {
        display: none;
      }
    }
  }
}

// Tooltip Classes
// ==========================================================================
@mixin tooltipPositioningFactory($tooltip-class: 'tooltip-inline') {
  $directions: ne, n, nw, w, e, s, sw, se;
  @each $direction in $directions {
    .#{$tooltip-class}-#{$direction} {
      @include tooltip-position($direction);
    }
  }
}

// Global
// ==========================================================================
// Define here your values
$tooltip-class: 'tooltip-inline' !default;

.#{$tooltip-class} {
  // Here you can override the default parameters
  @include tooltip($fade-in-delay: $quick-transition);
  @include tooltip-position('n');
}

@include tooltipPositioningFactory($tooltip-class: $tooltip-class);
