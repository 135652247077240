@-webkit-keyframes rotateInUpLeft {
	0% {-webkit-transform-origin: left bottom; -webkit-transform: rotate(90deg); opacity: 0;}
	100% {-webkit-transform-origin: left bottom; -webkit-transform: rotate(0); opacity: 1;}
}

@-moz-keyframes rotateInUpLeft {
	0% {-moz-transform-origin: left bottom; -moz-transform: rotate(90deg); opacity: 0;}
	100% {-moz-transform-origin: left bottom; -moz-transform: rotate(0); opacity: 1;}
}

@-ms-keyframes rotateInUpLeft {
	0% {-ms-transform-origin: left bottom; -ms-transform: rotate(90deg); opacity: 0;}
	100% {-ms-transform-origin: left bottom; -ms-transform: rotate(0); opacity: 1;}
}

@-o-keyframes rotateInUpLeft {
	0% {-o-transform-origin: left bottom; -o-transform: rotate(90deg); opacity: 0;}
	100% {-o-transform-origin: left bottom; -o-transform: rotate(0); opacity: 1;}
}

@keyframes rotateInUpLeft {
	0% {transform-origin: left bottom; transform: rotate(90deg); opacity: 0;}
	100% {transform-origin: left bottom; transform: rotate(0); opacity: 1;}
}

@mixin rotateInUpLeft($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rotateInUpLeft);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.rotateInUpLeft { @include rotateInUpLeft(); }