//
// Footer (Global)
// ==========================================================================
.layout main > footer,
body > main > footer {
  @include transition(all $standard-transition ease);
  @include size(100% auto);
  @include clearfix;
  position: relative;
  z-index: $z-content;
  background: $blue-dark;
  .container {
    @include transition(padding $standard-transition ease);
    @include padding(60px 0);
    line-height: 25px;
    @include max-screen($tablet) {
      @include padding(20px 0);
      text-align: center;
    }
  }
  hr {
    background: rgba($white, 0.2);
  }
  .terms {
    @include margin(0);
    float: left;
    font-size: 14px;
    color: rgba($white, 0.2);
    @include max-screen($desktop) {
      text-align: left;
      width: 50%;
    }
    @include max-screen($smart) {
      @include margin(0 0 20px 0);
      width: 100%;
      float: none;
      display: inline-block;
      text-align: center;
    }
  }
  .privacy {
    display: flex;
    justify-content: space-evenly;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-size: 12px;
    color: rgba($white, 0.2);
    @include max-screen($smart) {
      float: none;
      display: inline-block;
      text-align: center;
    }
  }
}

// Nav
// ==========================================================================
.layout main > footer,
body > main > footer {
  .footer-nav {
    float: left;
    @include max-screen($tablet) {
      width: 50%;
    }
    @include max-screen($smart) {
      width: 100%;
      float: none;
      display: inline-block;
      text-align: center;
    }
    li {
      float: left;
      display: inline-block;
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      @include max-screen($desktop) {
        @include margin(0);
        width: 50%;
        text-align: left;
      }
      @include max-screen($smart) {
        margin-bottom: 5px;
        width: 100%;
        float: none;
        display: block;
        text-align: center;
      }
      a {
        font-size: $base-font-size - 6;
        font-family: $sans-font-medium;
        color: $white;
        vertical-align: middle;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        @include max-screen($wide) {
          font-size: $base-font-size - 8;
        }
      }
    }
  }
}

// Links
// ==========================================================================
.layout main > footer,
body > main > footer {
  .footer-links {
    @include margin(0);
    @include clearfix;
    float: right;
    @include max-screen($smart) {
      margin-top: 10px;
      border-top: 1px solid rgba($white, 0.2);
      float: none;
      display: block;
      text-align: center;
    }
    li {
      float: left;
      display: inline-block;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
      @include max-screen($smart) {
        @include margin(0);
        @include padding(1em 1em 0 1em);
        width: 33.3%;
      }
      a {
        font-size: $base-font-size - 2;
        font-family: $sans-font-medium;
        color: $white;
        vertical-align: middle;
        &[data-icon="g"]:before { font-size: 17px; }
        &[data-icon="e"]:before { font-size: 18px; }
        &[data-icon="f"]:before { font-size: 24px; }
        @include max-screen($smart) {
          font-size: $base-font-size - 4;
        }
      }
    }
  }
}
