//
// Subnav
// ==========================================================================

// Core
// ==========================================================================
.section-flownav {
  @include size(100% 61px);
  @include padding(0);
  border-top: 1px solid $gray-one !important;
  border-bottom: 1px solid $gray-one;
  text-align: center;
  background: $gray-zero;
  @include max-screen($desktop) {
    height: auto;
  }
  &.section-is-scrolled {
    top: 90px;
  }
  ul {
    @include clearfix;
    width:  100%;
    display: inline-block;
    &.homeowner {
      li {
        &.active,
        &:focus,
        &:hover {
          @include box-shadow(0, -4px, 0, 0, $orange, $inset: true);
        }
      }
    }
    @include max-screen($desktop) {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    @for $i from 1 through 6 {
      li:first-child:nth-last-child(#{$i}),
      li:first-child:nth-last-child(#{$i}) ~ li {
        @include max-screen($tablet) {
          width: #{100%/$i};
        }
        @include max-screen($smart) {
          width: 100%;
        }
      }
    }
    li {
      @include transition(all $quick-transition ease);
      //width: 16.66642857%; //FIXME: Change to inline
      display: inline-block;
      float: left;
      @include max-screen($tablet) {
        text-align: center;
      }
      @include max-screen($smart) {
        float: none;
        display: block;
      }
      &.active,
      &:focus,
      &:hover {
        @include box-shadow(0, -4px, 0, 0, $teal, $inset: true);
        background: $white;
        a {
          color: $blue;
        }
      }
      &.complete {
        a {
          color: $blue;
        }
        .status {
          border-color: $green;
          &:after {
            @include position(absolute, 3px auto auto -1px);
            @include size(22px);
            line-height: 100%;
            text-transform: none;
            font-family: $icon-font-family;
            font-size: 12px;
            font-style: normal;
            color: $green;
            content: "u";
          }
        }
      }
      &.disabled {
        opacity: 0.5;
        &:hover {
          opacity: 0.1;
          @include box-shadow(0, -4px, 0, 0, $red, $inset: true);
          background: $white;
          a {
            color: $red;
          }
        }
      }
      a {
        color: $gray-two;
        display: block;
        @include max-screen($desktop) {
          @include padding(10px 20px);
          line-height: 30px;
        }
        @include max-screen($tablet) {
          @include margin(0);
          @include padding(10px 15px 5px 15px);
          font-size: 10px;
        }
        @include max-screen($smart) {
          @include padding(10px);
        }
        &:hover {
          @include opacity(1);
        }
      }
      i {
        @include max-screen($smart) {
          visibility: visible;
        }
      }
    }
  }
}

// Buttons
// ==========================================================================
.btn-flownav {
  @extend .btn;
  @include box-shadow(0, 0, 0, 1px, $gray-one);
  @include border-radius(0);
  line-height: 59px;
  background: transparent;
  color: inherit;
  font-weight: 600;
  @include max-screen($huge) {
    @include padding(0 30px);
  }
  @include max-screen($desktop) {
    @include padding(0 20px);
    line-height: 60px;
    letter-spacing: 1px;
    font-size: $base-font-size - 7;
  }
  @include max-screen($tablet) {
    @include padding(0 15px);
  }
  @include max-screen($smart) {
    @include padding(0 10px);
  }
  &:active,
  &.active {
    @include box-shadow(0, -4px, 0, 0, rgba($orange, 0));
  }
  &:focus,
  &.focus {
    background: transparent;
    color: inherit;
  }
  .status {
    @include size(22px);
    @include border-radius(100%);
    @include position(relative, -2px auto auto auto);
    @include margin(0 5px 0 0);
    vertical-align: middle;
    border: 2px solid $gray-two;
    display: inline-block;
    @include max-screen($desktop) {
      @include position(relative, auto auto auto auto);
      @include margin(0 auto);
      display: block;
    }
    @include max-screen($smart) {
      @include margin(0 5px 0 0);
      display: inline-block;
    }
  }
}
