@keyframes rubberBand {
	0% { transform: scale(1); }
	30% { transform: scaleX(1.25) scaleY(0.75); }
	40% { transform: scaleX(0.75) scaleY(1.25); }
	60% { transform: scaleX(1.15) scaleY(0.85); }
	100% { transform: scale(1); }
}


@-webkit-keyframes rubberband {
	0% { -webkit-transform: scale(1); }
	30% { -webkit-transform: scaleX(1.25) scaleY(0.75); }
	40% { -webkit-transform: scaleX(0.75) scaleY(1.25); }
	60% { -webkit-transform: scaleX(1.15) scaleY(0.85); }
	100% { -webkit-transform: scale(1); }
}

@-moz-keyframes rubberband {
	0% { -moz-transform: scale(1); }
	30% { -moz-transform: scaleX(1.25) scaleY(0.75); }
	40% { -moz-transform: scaleX(0.75) scaleY(1.25); }
	60% { -moz-transform: scaleX(1.15) scaleY(0.85); }
	100% { -moz-transform: scale(1); }
}

@-ms-keyframes rubberband {
	0% { -ms-transform: scale(1); }
	30% { -ms-transform: scaleX(1.25) scaleY(0.75); }
	40% { -ms-transform: scaleX(0.75) scaleY(1.25); }
	60% { -ms-transform: scaleX(1.15) scaleY(0.85); }
	100% { -ms-transform: scale(1); }
}

@-o-keyframes rubberband {
	0% { -o-transform: scale(1); }
	30% { -o-transform: scaleX(1.25) scaleY(0.75); }
	40% { -o-transform: scaleX(0.75) scaleY(1.25); }
	60% { -o-transform: scaleX(1.15) scaleY(0.85); }
	100% { -o-transform: scale(1); }
}

@keyframes rubberband {
	0% { transform: scale(1); }
	30% { transform: scaleX(1.25) scaleY(0.75); }
	40% { transform: scaleX(0.75) scaleY(1.25); }
	60% { transform: scaleX(1.15) scaleY(0.85); }
	100% { transform: scale(1); }
}

@mixin rubberband($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rubberband);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.rubberband { @include rubberband(); }
