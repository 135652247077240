@-webkit-keyframes fadeOutUpBig {
	0% {opacity: 1; -webkit-transform: translateY(0);}
	100% {opacity: 0; -webkit-transform: translateY(-2000px);}
}

@-moz-keyframes fadeOutUpBig {
	0% {opacity: 1; -moz-transform: translateY(0);}
	100% {opacity: 0; -moz-transform: translateY(-2000px);}
}

@-o-keyframes fadeOutUpBig {
	0% {opacity: 1; -o-transform: translateY(0);}
	100% {opacity: 0; -o-transform: translateY(-2000px);}
}

@keyframes fadeOutUpBig {
	0% {opacity: 1; transform: translateY(0);}
	100% {opacity: 0; transform: translateY(-2000px);}
}

@mixin fadeOutUpBig($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(fadeOutUpBig);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.fadeOutUpBig { @include fadeOutUpBig(); }