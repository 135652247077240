//
// Tags
// ==========================================================================
.tag-keyword {
  @include border-radius(15px);
  @include padding(0 20px);
  line-height: 30px;
  font-size: 10px;
  color: $gray;
  text-transform: uppercase;
  background: $gray-one;
  font-family: $sans-font-bold;
  display: inline-block;
}
.tag-green {
  @include border-radius(4px);
  @include padding(0 10px);
  line-height: 24px;
  font-size: 12px;
  color: $white;
  text-transform: uppercase;
  background: $green;
  font-family: $sans-font-bold;
  display: inline-block;
}
.tag-red {
  @extend .tag-green;
  background: $red;
}
.tag-upload {
  @extend .tag-green;
  background: $teal;
  display: inline-block;
  margin-left: 10px;
  text-transform: none;
  position: relative;
  top: -2px;
}
