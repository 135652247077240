//
// Contractors
// ==========================================================================

// Main @ Contractors
// ==========================================================================
main.contractors {
    .section-flownav {
        border-bottom: none;
        height: 60px;

        @include max-screen($desktop) {
            height: auto;
        }

        @include max-screen($smart) {
            border-bottom: 1px solid $gray-one;
        }

        ul {
            width: auto;

            li {
                width: auto;

                @include max-screen($desktop) {
                    width: 33.33%;
                }
            }
        }
    }
}

.contractor-terms {
    ul, ol {
        padding-left: 40px;
    }
}

// Section Grow
// ==========================================================================
.section-grow {
  @include clearfix;
  background: $gray-zero;
  border-bottom: 1px solid $gray-one;
  ul {
    @include margin(20px 0 40px 0);
    float: left;
    @include max-screen($tablet) {
      @include margin(20px auto);
    }
    li {
      @include margin(0 0 30px 0);
      @include padding(0 0 0 70px);
      position: relative;
      &[data-icon]:before {
        @include position(absolute, -8px auto auto 0);
        @include size(44px);
        font-size: 44px;
        color: $gray-two;
      }
      p {
        font-size: 16px;
        @include max-screen($tablet) {
          font-size: 14px;
          line-height: 24px;
        }
        @include max-screen($smart) {
          font-size: 12px;
          line-height: 22px;
        }
      }
      span {
        margin-right: 5px;
        color: $teal;
        font-family: $sans-font-medium;
      }
    }
  }
  .empty {
    @include max-screen($smart) {
      display: none;
    }
  }
  .img-asset {
    @include position(absolute, 0 auto auto 0);
    @include center(y);
    display: block;
    @include max-screen($tablet) {
      @include position(relative, auto auto auto auto);
      @include transform(none);
      @include margin(0 auto);
    }
    @include max-screen($smart) {
      display: none;
    }
  }
  .img-asset-opposite {
    @extend .img-asset;
    right: -110px;
    margin-left: 652px;
    margin-top: 22px;
    @include max-screen($desktop) {
      right: 0;
      left: auto;
      width: 50%;
    }
    @include max-screen($tablet) {
      display: none;
    }
  }
}
