@-webkit-keyframes flipOutY {
	0% {-webkit-transform: perspective(400px) rotateY(0deg); opacity: 1;}
	100% {-webkit-transform: perspective(400px) rotateY(90deg); opacity: 0;}
}
@-moz-keyframes flipOutY {
	0% {-moz-transform: perspective(400px) rotateY(0deg); opacity: 1;}
	100% {-moz-transform: perspective(400px) rotateY(90deg); opacity: 0;}
}
@-ms-keyframes flipOutY {
	0% {-ms-transform: perspective(400px) rotateY(0deg); opacity: 1;}
	100% {-ms-transform: perspective(400px) rotateY(90deg); opacity: 0;}
}
@-o-keyframes flipOutY {
	0% {-o-transform: perspective(400px) rotateY(0deg); opacity: 1;}
	100% {-o-transform: perspective(400px) rotateY(90deg); opacity: 0;}
}
@keyframes flipOutY {
	0% {transform: perspective(400px) rotateY(0deg); opacity: 1;}
	100% {transform: perspective(400px) rotateY(90deg); opacity: 0;}
}

@mixin flipOutY($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(flipOutY);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.flipOutY { @include flipOutY(); }