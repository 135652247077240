﻿@charset "UTF-8";
//
// Colors
// ==========================================================================

// Base
// ==========================================================================
$black:           #202227;
$white:           #FFFFFF;

// App
// ==========================================================================
$blue:            #1F506F;
$blue-dark:       #113E5B;
$teal:            #95CED7;
$teal-dark:       #7AB9C3;
$orange:          #F2693E;
$orange-light:    #F5A97D;
$orange-dark:     #D45028;
$gray:            #808285;
$gray-dark:       #696D72;
$green-light:     #43cb83;

// Grays
// ==========================================================================
$gray-zero:       #FBFBFB;
$gray-one:        #DDDEDE;
$gray-two:        #58585B;
$gray-three:      #a6a7a7;

// Misc.
// ==========================================================================
$facebook:        #39579A;
$twitter:         #69ACE0;
$instagram:       #E9E2D6;
$google:          #DF4B38;

// Validation
// ==========================================================================
$green:           #43CB83;
$red:             #E21E1E;
$seaGreen:        #2F915D;
$yellow:          #EBDE41;

// Plans
// ==========================================================================
$standard:        $orange;
$premier:         #C89C1F;
$value:           $green;
$deluxe:          #4E486E;
