//
// Structure
// ==========================================================================


// Base
// ==========================================================================
*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  @include margin(0);
  @include padding(0);
  position: relative;
  background: $white;
  color: $gray;
  font-size: $base-font-size;
  font-family: $base-font-family;
  line-height: $base-line-height;
  text-rendering: optimizeLegibility;
  &.is-fixed {
    overflow: hidden !important;
  }
}

// Wrappers
// ==========================================================================
.container {
  @include margin(0 auto);
  max-width: $container-width;
  width: 90%;
  position: relative;
  @include clearfix;
}
.row {
  @include clearfix;
}

// Sections
// ==========================================================================
section {
  @include padding(5em 0);
  @include clearfix;
  @include max-screen($tablet) {
    @include padding(4em 0);
  }
  @include max-screen($smart) {
    @include padding(3em 0);
  }
  @include max-screen($mobile) {
    @include padding(2em 0);
  }
  &:first-of-type {
    border-top: none;
  }
  .section-inner {
    @include clearfix;
    @include padding(0 0 2em 0);
  }
}

// Mobile First Grid
// ==========================================================================
.column {
  float: left;
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;

  // Columns
  &.full { width: 100%; }
  &.five-sixths { width: 83.33% }
  &.four-fifths { width: 80%; }
  &.two-fifths { width: 40%; }
  &.three-fourths { width: 75%; }
  &.two-thirds { width: 66.7%; }
  &.half { width: 50%; }
  &.third { width: 33.3%; }
  &.fourth { width: 25%; }
  &.fifth { width: 20%; }
  &.sixth { width: 16.66%; }
  &.flow-opposite { float: right; }

  // Breakpoint
  @include max-screen($tablet) {
    float: none;
    margin-bottom: 30px;
    &.full,
    &.five-sixths,
    &.four-fifths,
    &.two-fifths,
    &.three-fourths,
    &.two-thirds,
    &.half,
    &.third,
    &.fourth,
    &.fifth,
    &.sixth,
    &.flow-opposite {
      width: 100%;
    }
  }
  @include max-screen($smart) {
    margin-bottom: 20px;
  }

  // Sub-Column Structure
  .column {
    position: relative;
    @include max-screen($tablet) {
      @include padding(0);
    }
    &:first-child {
      padding-left: 0;
      @include max-screen($tablet) {
        @include padding(0);
      }
    }
    &:last-child {
      padding-right: 0;
      @include max-screen($tablet) {
        @include padding(0);
      }
    }
  }
}

// Lists
// ==========================================================================
ul {
  @include margin(0);
  @include padding(0);
  text-align: left;
}

// Elements
// ==========================================================================
hr {
  @include margin(15px auto);
  @include size(100% 1px);
  border: none;
  background: $gray-one;
  display: block;
  @include max-screen($smart) {
    @include margin(15px auto);
  }
  &.light {
    @include opacity(0.2);
    background: $white;
  }
}

// Utilities
// ==========================================================================
.remove-padding { padding-bottom: 0; }
.remove-border { border: none; }
div.floating { display:inline-block; float:none; }
.textCenter { text-align:center; }

// Clearfix by Nicolas Gallagher
// ==========================================================================
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.hyphenli {
   list-style-type: none !important;
   text-indent: -10px;
}
