﻿@charset "UTF-8";
//
// Variables
// ==========================================================================

// Typography
// ==========================================================================

// Font Weights
$light:                     300;
$normal:                    400;
$bold:                      600;
$extra-bold:                700;

// Sans Font Families
$sans-font-light:           'brandon_grotesquelight', serif !default;
$sans-font-regular:         'brandon_grotesque_regularRg', serif !default;
$sans-font-medium:          'brandon_grotesquemedium', serif !default;
$sans-font-bold:            'brandon_grotesquebold', serif !default;

// Title Font Families
$serif-font-bold:           'brandon_grotesquebold', serif !default;

// Sub Title Font Familes
$serif-subfont-regular:     'brandon_grotesque_regularRg', serif !default;

// Icon Font Family
$icon-font-family:          'landmark', serif !default;

// Fixed Font Family
$mono-font-family:          Monaco, Menlo, Consolas, 'Courier New', monospace !default;

// Base Variables
$base-font-family:          $sans-font-regular !default;
$base-font-size:            18px !default;
$base-line-height:          28px !default;

// Fixed Font
$fixed-font-family:         $mono-font-family;
$fixed-font-size:           85% !default;
$fixed-line-height:         $base-line-height;

// Containers
// ==========================================================================
$container-width:           em(1250px);    // 78.125em;

// Breakpoints
// ==========================================================================
$huge:                      em(1680px);    // 105em;
$wide:                      em(1280px);    // 80em;
$desktop:                   em(1024px);    // 64em;
$tablet:                    em(768px);     // 48em;
$smart:                     em(480px);     // 30em;
$mobile:                    em(320px);     // 20em;

$pixeloffset:               em(1px);

// Height Breakpoints
// ==========================================================================
$laptop-height:             em(816px);     // 51em;
$ios-height:                em(460px);     // 28.75em;

// Transitions
// ==========================================================================
$slow-transition:           0.6s;
$standard-transition:       0.4s;
$quick-transition:          0.2s;

// Layout
// ==========================================================================
$header-height:             140px;
$footer-height:             290px;
$aside-width:               300px;
$modal-width:               600px;
$admin-nav-width:           275px;
$order-header-height:       106px;

$logo-width:                210px;
$logo-height:               70px;

$logo-bug-width:            57px;
$logo-bug-height:           56px;

// Buttons
// ==========================================================================
$btn-huge-height:           60px;
$btn-height:                50px;
$btn-small-height:          40px;

// Layers
// ==========================================================================
$z-bottom:                  -1;
$z-below-content:           10;
$z-content:                 20;
$z-above-content:           30;
$z-modal:                   40;
