@-webkit-keyframes flip {
	0% {-webkit-transform: perspective(400px) rotateY(0);-webkit-animation-timing-function: ease-out;}
	40% {-webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg); -webkit-animation-timing-function: ease-out;}
	50% {-webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1); -webkit-animation-timing-function: ease-in;}
	80% {-webkit-transform: perspective(400px) rotateY(360deg) scale(.95); -webkit-animation-timing-function: ease-in;}
	100% {-webkit-transform: perspective(400px) scale(1); -webkit-animation-timing-function: ease-in;}
}

@-moz-keyframes flip {
	0% {-moz-transform: perspective(400px) rotateY(0); -moz-animation-timing-function: ease-out;}
	40% {-moz-transform: perspective(400px) translateZ(150px) rotateY(170deg); -moz-animation-timing-function: ease-out;}
	50% {-moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1); -moz-animation-timing-function: ease-in;}
	80% {-moz-transform: perspective(400px) rotateY(360deg) scale(.95); -moz-animation-timing-function: ease-in;}
	100% {-moz-transform: perspective(400px) scale(1); -moz-animation-timing-function: ease-in;}
}

@-o-keyframes flip {
	0% {-o-transform: perspective(400px) rotateY(0); -o-animation-timing-function: ease-out;}
	40% {-o-transform: perspective(400px) translateZ(150px) rotateY(170deg); -o-animation-timing-function: ease-out;}
	50% {-o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1); -o-animation-timing-function: ease-in;}
	80% {-o-transform: perspective(400px) rotateY(360deg) scale(.95); -o-animation-timing-function: ease-in;}
	100% {-o-transform: perspective(400px) scale(1); -o-animation-timing-function: ease-in;}
}

@keyframes flip {
	0% {transform: perspective(400px) rotateY(0); animation-timing-function: ease-out;}
	40% {transform: perspective(400px) translateZ(150px) rotateY(170deg); animation-timing-function: ease-out;}
	50% {transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1); animation-timing-function: ease-in;}
	80% {transform: perspective(400px) rotateY(360deg) scale(.95); animation-timing-function: ease-in;}
	100% {transform: perspective(400px) scale(1); animation-timing-function: ease-in;}
}

@mixin flip($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include transform-style(preserve-3d);
	@include animation-name(flip);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.flip { @include flip(); }
