﻿@charset "UTF-8";
//
// Fonts
// ==========================================================================

// Rockwell
// ==========================================================================
@font-face {
  font-family: "rockwell-bold";
  src: url("../fonts/rockwell/rockwell-bold.eot");
  src: url("../fonts/rockwell/rockwell-bold.eot?#iefix") format("embedded-opentype"),
  url("../fonts/rockwell/rockwell-bold.woff2") format("woff2"),
  url("../fonts/rockwell/rockwell-bold.woff") format("woff"),
  url("../fonts/rockwell/rockwell-bold.ttf") format("truetype"),
  url("../fonts/rockwell/rockwell-bold.svg#rockwellbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

// BrandonGrotesque
// ==========================================================================
/*@font-face {
  font-family: "brandon-grotesque";
  src: url("../fonts/brandon-grotesque/brandon-grotesque-regular.otf");
  src: url("../fonts/brandon-grotesque/brandon-grotesque-regular.otf?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}*/
@font-face {
    font-family: 'brandon_grotesqueblack';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-black-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueblack_italic';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquebold';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-bold-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquebold_italic';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquelight';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-light-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquelight_italic';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquemedium';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-medium-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueMdIt';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesque_regularRg';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-regular-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueRgIt';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquethin';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-thin-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquethin_italic';
    src: url('../fonts/brandon-grotesque/brandon-grotesque-thinitalic-webfont.woff2') format('woff2'),
         url('../fonts/brandon-grotesque/brandon-grotesque-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// Steelfish
// ==========================================================================
@font-face {
  font-family: "steelfish-regular";
  src: url("../fonts/steelfish/steelfish-regular.eot");
  src: url("../fonts/steelfish/steelfish-regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/steelfish/steelfish-regular.woff2") format("woff2"),
  url("../fonts/steelfish/steelfish-regular.woff") format("woff"),
  url("../fonts/steelfish/steelfish-regular.ttf") format("truetype"),
  url("../fonts/steelfish/steelfish-regular.svg#steelfishregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Landmark Icons
// ==========================================================================
@font-face {
  font-family: "landmark";
  src:url("../fonts/landmark/landmark.eot");
  src:url("../fonts/landmark/landmark.eot?#iefix") format("embedded-opentype"),
  url("../fonts/landmark/landmark.woff") format("woff"),
  url("../fonts/landmark/landmark.ttf") format("truetype"),
  url("../fonts/landmark/landmark.svg#landmark") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "landmark" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "landmark" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-homeowner-small:before {
  content: "\61";
}
.icon-professional-small:before {
  content: "\62";
}
.icon-contractor-small:before {
  content: "\63";
}
.icon-resources-small:before {
  content: "\64";
}
.icon-facebook:before {
  content: "\65";
}
.icon-google:before {
  content: "\66";
}
.icon-twitter:before {
  content: "\67";
}
.icon-arrow-down:before {
  content: "\68";
}
.icon-arrow-left:before {
  content: "\69";
}
.icon-arrow-right:before {
  content: "\6a";
}
.icon-arrow-up:before {
  content: "\6b";
}
.icon-close:before {
  content: "\6c";
}
.icon-scroll-down:before {
  content: "\6d";
}
.icon-scroll-left:before {
  content: "\6e";
}
.icon-scroll-right:before {
  content: "\6f";
}
.icon-scroll-up:before {
  content: "\70";
}
.icon-contractor:before {
  content: "\71";
}
.icon-homeowner:before {
  content: "\72";
}
.icon-professional:before {
  content: "\73";
}
.icon-resources:before {
  content: "\74";
}
.icon-check:before {
  content: "\75";
}
.icon-standard:before {
  content: "\76";
}
.icon-premier:before {
  content: "\77";
}
.icon-value:before {
  content: "\78";
}
.icon-deluxe:before {
  content: "\79";
}
.icon-iphone:before {
  content: "\7a";
}
.icon-email:before {
  content: "\41";
}
.icon-no:before {
  content: "\42";
}
.icon-yes:before {
  content: "\43";
}
.icon-coverage:before {
  content: "\44";
}
.icon-dashboard:before {
  content: "\45";
}
.icon-approval:before {
  content: "\46";
}
.icon-happy:before {
  content: "\47";
}
.icon-protection:before {
  content: "\48";
}
.icon-support:before {
  content: "\49";
}
.icon-mouse:before {
  content: "\4a";
}
.icon-customers:before {
  content: "\4b";
}
.icon-paycheck:before {
  content: "\4c";
}
.icon-email-small:before {
  content: "\4d";
}
.icon-location-small:before {
  content: "\4e";
}
.icon-phone-small:before {
  content: "\4f";
}
.icon-social-twitter:before {
  content: "\50";
}
.icon-social-facebook:before {
  content: "\51";
}
.icon-social-google:before {
  content: "\52";
}
.icon-social-linkedin:before {
  content: "\53";
}
.icon-social-youtube:before {
  content: "\54";
}
.icon-social-flickr:before {
  content: "\55";
}
.icon-info:before {
  content: "\56";
}
.icon-star:before {
  content: "\57";
}
.icon-selected-icon:before {
  content: "\58";
}
.icon-notices:before {
  content: "\59";
}
.icon-history:before {
  content: "\5a";
}
.icon-profile:before {
  content: "\30";
}
.icon-marketing:before {
  content: "\31";
}
.icon-orders:before {
  content: "\32";
}
.icon-dash:before {
  content: "\33";
}
.icon-ticket:before {
  content: "\34";
}
.icon-invoice:before {
  content: "\35";
}
.icon-voc:before {
  content: "\36";
}
.icon-logout:before {
  content: "\37";
}
.icon-upload:before {
  content: "\38";
}
.icon-download:before {
  content: "\39";
}
.icon-edit:before {
  content: "\21";
}
.icon-search:before {
  content: "\22";
}
.icon-dots:before {
  content: "\23";
}
