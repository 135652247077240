//
// Main @ Admin
// ==========================================================================
.layout > main.admin {
  .admin-wrap {
    @include position(absolute, ($header-height - 50) 0 0 0);
    overflow: hidden;
    // TODO - Somehow after all the updates this no longer seems necessary. Some testing is probably still needed to verify.
    // @include max-screen($tablet) {
    //   top: $header-height - 50;
    // }
  }
}

// Admin Nav
// ==========================================================================
.admin-nav {
  @include transition(all $quick-transition ease);
  @include position(absolute, 0 auto 0 auto);
  z-index: $z-content;
  border-right: 1px solid $gray-one;
  width: $admin-nav-width;
  background: $gray-zero;
  @include max-screen($desktop) {
    width: $admin-nav-width - 50;
  }
  @include max-screen($tablet) {
    left: -($admin-nav-width - 50);
  }
  .nav-body {
    @include position(absolute, 0 0 60px 0);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .nav-footer {
    @include position(absolute, auto 0 0 0);
    li {
      border-top: 1px solid $gray-one;
      border-bottom: none;
      a {
        color: $orange;
        padding-left: 30px;
        &:hover {
          @include opacity(0.7);
          color: $orange;
        }
        &[data-icon]:before {
          @include position(absolute, 0 0 auto auto);
          @include center(y);
          @include margin(0 20px 0 0);
          font-size: 16px;
        }
      }
    }
  }
  li {
    border-bottom: 1px solid $gray-one;
    &.active {
      a {
        @include box-shadow(4px, 0, 0, 0, inset);
        color: $blue;
      }
    }
    a {
      @include padding(16px 0);
      position: relative;
      font-size: 12px;
      font-family: $sans-font-bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: block;
      background: $white;
      color: $gray-two;
      @include max-screen($desktop) {
        font-size: 11px;
      }
       @include max-screen($tablet) {
        @include padding(10px 0);
       }
      &:hover {
        @include opacity(1);
        color: $blue;
      }
      &[data-icon]:before {
        @include margin(0 15px 0 30px);
        vertical-align: middle;
        font-size: 24px;
        @include max-screen($desktop) {
          @include margin(0 10px 0 20px);
          font-size: 14px;
        }
      }
    }
    .badge {
      @include position(absolute, 0 20px auto auto);
      @include center(y);
      @include border-radius(100%);
      @include size(18px);
      background: $orange;
      text-align: center;
      color: $white;
      font-size: 11px;
      line-height: 18px;
      @include max-screen($desktop) {
        right: 10px;
      }
    }
  }
}

.badge-icon {
    @include position(absolute, 0 20px auto auto);
    @include center(y);
    @include border-radius(100%);
    @include size(18px);
    background: $orange;
    text-align: center;
    color: $white;
    font-size: 11px;
    line-height: 18px;
    @include max-screen($desktop) {
        right: 10px;
    }
}

// Admin Nav
// ==========================================================================
.admin-canvas {
  @include transition(all $quick-transition ease);
  @include position(absolute, 0 0 0 $admin-nav-width);
  @include max-screen($desktop) {
    left: $admin-nav-width - 50;
  }
  @include max-screen($tablet) {
    left: 0
  }
  .fieldset-title {
    @include margin(10px 0);
  }
  .container {
    @include margin(0);
    @include padding(0 40px);
    max-width: none;
    width: 100%;
    @include max-screen($tablet) {
      @include padding(0 20px);
    }
    @include max-screen($smart) {
      @include padding(0 10px);
    }
  }
  .admin-nav-toggle {
    @include transition(all $quick-transition ease);
    @include margin(0 10px 0 0);
    left: 0;
    vertical-align: middle;
    display: none;
    color: $orange;
    @include max-screen($tablet) {
      display: inline-block;
    }
  }
  .admin-title {
    @include margin(0 0 30px 0);
    @include padding(15px 0 16px 0);
    letter-spacing: 0;
    position: relative;
    color: $orange;
    font-family: $serif-font-bold;
    text-transform: capitalize;
    border-bottom: 1px solid $gray-one;
    @include max-screen($desktop) {
      font-size: 22px;
    }
    @include max-screen($tablet) {
      font-size: 20px;
      margin-bottom: 10px;
    }
    @include max-screen($smart) {
      font-size: 18px;
    }
  }
  .admin-title-left {
    @include margin(1px 20px 0 0);
    @include padding(0 20px 0 0);
    color: $gray;
    font-family: $sans-font-regular;
    font-size: 14px;
    border-right: 1px solid $gray-one;
    float: left;
    @include max-screen($tablet) {
      margin-right: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
    i {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .admin-title-right {
    @include position(absolute, 0 0 auto auto);
    @include center(y);
    letter-spacing: 0;
    font-family: $sans-font-medium;
    @include max-screen($tablet) {
      @include position(relative, auto auto auto auto);
      @include transform(none);
      @include margin(15px 0 0 0);
      @include padding(15px 0 0 0);
      border-top: 1px solid $gray-one;
    }
    .code {
      @include margin(0);
      @include padding(2px 10px);
      font-size: 13px;
      @include max-screen($tablet) {
        font-size: 11px;
      }
    }
    .link {
      @include margin(0 0 0 30px);
      color: $orange;
      font-size: 14px;
      @include max-screen($tablet) {
        @include margin(0 0 0 10px);
        font-size: 12px;
      }
    }
    i {
      @include margin(0 0 0 10px);
      @include position(relative, 1px auto auto auto);
      vertical-align: middle;
      &[data-icon="j"]:before {
        font-size: 12px;
      }
    }
    .field-wrap {
      @include margin(0);
    }
    form {
      @include clearfix;
    }
    fieldset {
      @include margin(0 0 0 20px);
      display: inline-block;
      @include max-screen($smart) {
        margin-left: 10px;
      }
      .link {
        color: $gray;
        font-family: $sans-font-regular;
      }
    }
    input {
      @include border-radius(0);
      font-size: 12px;
      background: transparent;
      border: 0;
      border-left: 1px solid $gray-one;
    }
  }
  .code {
    @include border-radius(2px);
    @include margin(10px 0 0 0);
    @include padding(10px 20px);
    background: $gray-zero;
    display: inline-block;
    font-family: $mono-font-family;
    font-size: 18px;
    color: $blue;
    @include max-screen($tablet) {
      @include padding(5px 15px);
      font-size: 14px;
    }
  }
  .well {
    @include clearfix;
    padding-right: 50px;
    position: relative;
    &.default {
      @include max-screen($tablet) {
        @include padding(10px 80px 10px 20px);
      }
      @include max-screen($smart) {
        @include padding(10px);
      }
    }
    &.details {
      @include margin(0 0 30px 0);
      @include max-screen($tablet) {
        margin-bottom: 10px;
      }
      h4 {
        font-size: 16px;
      }
      .title {
        font-size: 14px;
      }
    }
    @include max-screen($tablet) {
      @include padding(10px 50px 10px 20px);
    }
    .close {
      @include position(absolute, 20px 20px auto auto);
      font-size: 16px;
      color: $orange;
      display: block;
    }
    h4 {
      font-family: $sans-font-medium;
      letter-spacing: 0;
      text-transform: capitalize;
      font-size: 18px;
      .title {
        font-style: italic;
        font-family: $sans-font-light;
        color: $gray;
      }
      @include max-screen($tablet) {
        @include margin(0);
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      @include max-screen($tablet) {
        font-size: 12px;
        line-height: 22px;
      }
    }
    .edit {
      @include position(absolute, auto 30px auto auto);
      @include center(y);
      @include border-radius(4px);
      @include padding(0 12px);
      @include size(auto 24px);
      line-height: 24px;
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $white;
      background: $orange;
      @include max-screen($tablet) {
        right: 20px;
      }
      @include max-screen($smart) {
        @include position(relative, auto auto auto auto);
        @include transform(none);
        @include padding(3px 10px);
      }
    }
    .status {
      @extend .edit;
      &.cancelled {
        background: $gray-two;
      }
      &.waiting {
        background: $yellow;
      }
      &.completed {
        background: $green;
      }
    }
    .btn {
      @include margin(20px 0);
    }
  }
  .well-meta {
    @include clearfix;
    @include margin(-10px 0 10px 0);
    @include max-screen($tablet) {
      @include margin(10px 0 0 0);
    }
    li {
      @include margin(0 30px 0 0);
      font-family: $sans-font-light;
      float: left;
      display: inline-block;
      font-size: 14px;
      b {
        font-family: $sans-font-medium;
      }
      a {
        color: $orange;
      }
    }
  }
  h5 {
    position: relative;
    font-family: $sans-font-medium;
    letter-spacing: 0;
    text-transform: capitalize;
    font-size: 16px;
    color: $orange;
    .title {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-family: $sans-font-light;
      color: $gray;
    }
    time {
      @include position(absolute, 0 0 auto auto);
      @include margin(0);
      font-family: $sans-font-light;
      text-transform: capitalize;
      color: $gray-two;
      font-size: 12px;
      text-align: right;
    }
    @include max-screen($tablet) {
      @include margin(0);
      font-size: 14px;
    }
  }
  time {
    @include margin(10px 0 0 0);
    font-family: $sans-font-medium;
    display: block;
    color: $blue;
    font-size: 12px;
    text-transform: uppercase;
  }
  .send {
    border-top: 1px solid $gray-one;
    @include position(absolute, auto 40px 0 40px);
    .btn-send {
      @include position(absolute, 0 0 auto auto);
      @include center(y);
      @include border-radius(4px);
      @include padding(0 12px);
      @include size(auto 24px);
      line-height: 24px;
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $white;
      background: $orange;
    }
    input {
      @include padding(20px 50px 20px 20px);
      background: transparent;
      border: none;
    }
  }
  .btn-actions {
    margin-bottom: 30px;
    .column {
      padding-right: 0;
    }
    a, button {
      color: $gray-two;
      width: 100%;
      font-size: 10px;
      &:focus {
        color: $white;
      }
      &:hover {
        color: $white;
      }
    }
  }
}
.exec-bio-header {
    float: left;
    padding-top: 60px;

    @include min-screen(620px) {
        @include margin(0 0 0 20%);
    }
    @include min-screen($tablet) {
        @include margin(0 0 0 24%);
    }
    @include min-screen(1100px){
        @include margin(0 0 0 30%);
    }
    @include max-screen($smart) {
        @include margin(0 5px 0 0);
    }
}

// Admin Cards
// ==========================================================================
.card-list {
  @include clearfix;
  @include max-screen($tablet) {
    padding-top: 30px;
  }
  @include max-screen($smart) {
    padding-top: 10px;
  }
  .card {
    @include margin(0 30px 30px 0);
    @include size(200px auto);
    @include border-radius(4px);
    @include box-shadow(0, 2px, 4px, 0, rgba($black, 0.2));
    @include opacity(1);
    position: relative;
    background: $white;
    overflow: hidden;
    float: left;
    display: inline-block;
    @include max-screen($tablet) {
      &:nth-child(2) {
        margin-right: 0;
      }
      width: 48%;
      margin-right: 4%;
    }
    @include max-screen($smart) {
      @include margin(0 0 20px 0);
      width: 100%;
      float: none;
      display: block;
    }
    &:hover {
      a {
        @include opacity(1);
      }
    }
    a {
      @include border-radius(4px);
      @include position(absolute, 0 0 0 0);
      @include transition(all $quick-transition ease);
      @include opacity(0);
      overflow: hidden;
      z-index: $z-content;
      color: $white;
      text-align: center;
      font-size: 12px;
      font-family: $sans-font-bold;
      &:hover {
        @include opacity(1);
      }
      &:before {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        background: rgba($blue, 0.7);
        content: "";
      }
      span {
        @include position(absolute);
        @include center;
        @include margin(0 auto);
        display: block;
        line-height: 16px;
        z-index: $z-content + 1;
      }
      i {
        @include margin(0 auto 20px auto);
        font-size: 24px;
        display: block;
      }
    }
    img {
      max-height: 200px;
      @include max-screen($tablet) {
        width: 100%;
        max-height: none;
      }
    }
  }
  .card-meta {
    @include padding(15px);
    font-family: $sans-font-bold;
    font-size: 12px;
    color: $gray;
  }
}

// Main @ Admin @ Contractor
// ==========================================================================
.layout > main.admin-contractor {
  .admin-nav {
    .nav-footer li a {
      color: $teal;
    }
  }
  .admin-canvas {
    .admin-title {
      color: $teal;
    }
    .well {
      margin-top: 20px;
    }
    .table {
      margin-top: 30px;
    }
    p > .title {
      @include margin(10px 0 0 0);
      color: $teal;
      display: block;
      font-size: 16px;
    }
  }
}
