// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes rollIn {
	0% { opacity: 0; -webkit-transform: translateX(-100%) rotate(-120deg);}
	100% { opacity: 1; -webkit-transform: translateX(0px) rotate(0deg);}
}

@-moz-keyframes rollIn {
	0% { opacity: 0; -moz-transform: translateX(-100%) rotate(-120deg);}
	100% { opacity: 1; -moz-transform: translateX(0px) rotate(0deg);}
}

@-ms-keyframes rollIn {
	0% { opacity: 0; -ms-transform: translateX(-100%) rotate(-120deg);}
	100% { opacity: 1; -ms-transform: translateX(0px) rotate(0deg);}
}

@-o-keyframes rollIn {
	0% { opacity: 0; -o-transform: translateX(-100%) rotate(-120deg);}
	100% { opacity: 1; -o-transform: translateX(0px) rotate(0deg);}
}

@keyframes rollIn {
	0% { opacity: 0; transform: translateX(-100%) rotate(-120deg);}
	100% { opacity: 1; transform: translateX(0px) rotate(0deg);}
}

@mixin rollIn($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rollIn);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.rollIn { @include rollIn(); }