//
// Flow (Application & Ordering)
// ==========================================================================

// Main @ Flow
// ==========================================================================
main.flow {
    @include transition(all $standard-transition ease);
    margin-top: $header-height;

    @include max-screen($tablet) {
        margin-top: $header-height - 50;
    }
}

// Section Flow
// ==========================================================================
.section-flow {
    background-color: rgb(251,251,251);
    min-height: 650px;

    p {
        font-size: 15px;
        line-height: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include max-screen($tablet) {
            font-size: $base-font-size - 6;
            line-height: $base-line-height - 6;
        }
    }

    ol {
        @include margin(0 0 20px 0);
        padding-left: 20px;
        font-size: 14px;
        list-style-position: outside;

        @include max-screen($tablet) {
            font-size: $base-font-size - 6;
            line-height: $base-line-height - 6;
        }

        ul,
        ol {
            padding-left: 40px;

            li {
                list-style-type: disc;
            }
        }
    }

    .edit {
        @include position(absolute, auto 0px auto auto);
        @include border-radius(4px);
        @include padding(0 12px);
        @include size(auto 24px);
        top: 50%;
        bottom: auto;
        transform: translate(-50%);
        line-height: 24px;
        font-family: $sans-font-medium;
        font-size: 12px;
        color: $white;
        background: $orange;

        @include max-screen($tablet) {
            right: 20px;
        }
    }
}

// Wells
// ==========================================================================
.well {
    @include margin(20px 0 0 0);
    @include padding(20px 30px);
    @include border-radius(6px);
    background: rgba($orange, 0.04);
    border: 2px solid $orange;

    @include max-screen($tablet) {
        @include padding(20px);
    }

    @include max-screen($smart) {
        @include padding(15px);
    }

    &.default {
        border-width: 1px;
        border-color: $gray-one;
        background: $gray-zero;
    }

    &.search {
        .container {
            width: 100%;
        }

        h3 {
            @include margin(0 0 10px 0);
            @include padding(0 0 10px 0);
            color: $gray-dark;
            border-bottom: 1px solid $gray-one;
            font-family: $sans-font-medium;
            white-space: nowrap;
            font-size: 16px;

            @include max-screen($tablet) {
                @include margin(20px 0);
                font-size: 14px;
            }
        }

        .search-results {
            li {
                @include margin(0 0 10px 0);
                @include padding(10px 80px 10px 15px);
                @include border-radius(4px) border: 1px solid $gray-one;
                background: $white;
                font-size: 14px;
                color: $blue;
                position: relative;

                &.empty {
                    padding-right: 15px;
                    background: $gray-one;
                }

                &.selected {
                    background: rgba($orange, 0.04);
                    border-color: $orange;

                    h4 {
                        color: $orange;
                    }
                }
            }

            h4 {
                @include margin(0 0 5px 0);
                font-family: $sans-font-bold;
                text-transform: capitalize;
                letter-spacing: 0;
                font-size: 16px;
            }

            p {
                line-height: 20px;
                color: $gray;
                font-family: $sans-font-regular;
            }

            .edit {
                @include position(absolute, auto 30px auto auto);
                @include center(y);
                @include border-radius(4px);
                @include padding(0 12px);
                @include size(auto 24px);
                line-height: 24px;
                font-family: $sans-font-medium;
                font-size: 12px;
                color: $white;
                background: $orange;

                @include max-screen($tablet) {
                    right: 20px;
                }
            }
        }
    }

    .field-wrap {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    p {
        color: $orange;
        font-family: $sans-font-medium;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
