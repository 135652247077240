@charset "UTF-8";
//
// Mixins
// ==========================================================================


// Opacity
// ==========================================================================
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity-ie)";
}

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}

// Image Serving
// ==========================================================================
// Modernizr class and Sass content inject for fallback code
@mixin no-svg { .no-svg & { @content } }
// Mixin that requires filename without path or extension and optional extension override
@mixin svg-bg($filename, $background-size, $extension: 'png') {
  background-image: url('../images/' + $filename + '.svg');
  background-repeat: no-repeat;
  @include background-size($background-size);
  @include no-svg {
    @include retina-image('../images/' + $filename, $background-size, $extension, $retina-filename: '../images/#{$filename}@2x');
  }
}

// The following by http://37signals.com/svn/posts/3271-easy-retina-ready-images-using-scss
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// Fluid Aspect Ratio Scaling
// http://voormedia.com/blog/2012/11/responsive-background-images-with-fixed-or-fluid-aspect-ratios
@mixin fluid-ratio($large-size, $small-size) {
  $width-large: nth($large-size, 1);
  $width-small: nth($small-size, 1);
  $height-large: nth($large-size, 2);
  $height-small: nth($small-size, 2);
  $slope: ($height-large - $height-small) / ($width-large - $width-small);
  $height: $height-small - $width-small * $slope;
  padding-top: $slope * 100%;
  height: $height;
  @include background-size(cover);
}

// Text
// ==========================================================================
@mixin word-wrap() {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin hyphenate() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

// UI
// ==========================================================================
// The following from http://web-design-weekly.com/blog/2013/05/12/handy-sass-mixins/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  // stops bg color from leaking outside the border:
}

@mixin background-size ($size:auto) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  background-size: $size;
}

@mixin background-image-cover ($url) {
  background: url(#{$url}) no-repeat center center;
  @include background-size(cover);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$url}', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$url}', sizingMethod='scale')";
}

// Font
// ==========================================================================
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Blur
// ==========================================================================
@mixin blur($px: 0) {
  -webkit-filter: blur(#{$px});
  -moz-filter: blur(#{$px});
  -ms-filter: blur(#{$px});
  -o-filter: blur(#{$px});
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation=\'#{$px}\' /></filter></svg>#blur");
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$px}');
}

// Shadows
// ==========================================================================
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  }
  @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

// Layout
// ==========================================================================
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Center vertically and/or horizontally an absolute positioned element
// ==========================================================================
@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
    @include backface-visibility(hidden);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
    @include backface-visibility(hidden);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
    @include backface-visibility(hidden);
  }
}
