//
// Subnav
// ==========================================================================

// Core
// ==========================================================================
.section-subnav {
  @include size(100% 81px);
  @include padding(0);
  text-align: center;
  background: $gray-zero;
  border-bottom: 1px solid $gray-one;
  @include max-screen($desktop) {
    height: 61px;
  }
  @include max-screen($smart) {
    height: auto;
  }
  .container {
    @include max-screen($tablet) {
      width: 100%;
    }
  }
  ul {
    @include clearfix;
    display: inline-block;
    @include max-screen($tablet) {
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    @for $i from 1 through 4 {
      li:first-child:nth-last-child(#{$i}),
      li:first-child:nth-last-child(#{$i}) ~ li {
        @include max-screen($tablet) {
          width: #{100%/$i};
        }
        @include max-screen($smart) {
          width: 100%;
        }
      }
    }
    li {
      @include transition(all $quick-transition ease);
      display: inline-block;
      float: left;
      @include max-screen($tablet) {
        text-align: center;
      }
      @include max-screen($smart) {
        float: none;
        display: block;
      }
      a {
        color: $gray-two;
        @include max-screen($tablet) {
          @include margin(0);
        }
        &:hover {
          @include opacity(1);
        }
      }
      i {
        @include max-screen($tablet) {
          visibility: hidden;
        }
        @include max-screen($smart) {
          visibility: visible;
        }
      }
    }
  }
}

// Buttons
// ==========================================================================
.btn-subnav {
  @extend .btn;
  @include box-shadow(0, 0, 0, 0, rgba($orange, 0));
  @include border-radius(0);
  line-height: 80px;
  background: transparent;
  color: inherit;
  @include max-screen($desktop) {
    @include padding(0 20px);
    line-height: 60px;
    letter-spacing: 1px;
    font-size: $base-font-size - 7;
  }
  @include max-screen($tablet) {
    @include padding(0 15px);
  }
  @include max-screen($smart) {
    @include padding(0 10px);
  }
  &:active,
  &.active {
    @include box-shadow(0, -2px, 0, 0, rgba($orange, 0));
  }
  &:focus,
  &.focus {
    background: transparent;
    color: inherit;
  }
}

// Groups
// ==========================================================================
.section-subnav {
  ul.homeowner {
    li.active,
    li.focus,
    li:hover {
      @include box-shadow(0, -2px, 0, 0, $orange, $inset: true);
      a {
        color: $orange;
      }
    }
  }
  ul.professionals {
    li.active,
    li:hover {
      @include box-shadow(0, -2px, 0, 0, $blue, $inset: true);
      a {
        color: $blue;
      }
    }
  }
  ul.contractors {
    li.active,
    li:hover {
      @include box-shadow(0, -2px, 0, 0, $teal, $inset: true);
      a {
        color: $teal;
      }
    }
  }
}