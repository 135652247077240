.modal-open-close-animation {
  background: white;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -53%);
  -ms-transform: translate(-50%, -53%);
  -webkit-transform: translate(-50%, -53%);
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-delay: 0.2s;
  transition-duration: 0.4s; }

.modal-open-close-animation-on {
  opacity: 1;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); }
