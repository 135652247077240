//
// Aside (Global)
// ==========================================================================
.layout > aside {
  @include position(fixed, 0 0 0 auto);
  @include size($aside-width 100vh);
  @include margin(0);
  @include padding(0);
  z-index: $z-above-content;
  background-color: darken($blue, 5%);
  overflow-y: auto;
  // Force Hardware Acceleration in WebKit
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform $standard-transition 0s, visibility 0s $standard-transition;
  -moz-transition: -moz-transform $standard-transition 0s, visibility 0s $standard-transition;
  transition: transform $standard-transition 0s, visibility 0s $standard-transition;
  visibility: hidden;
  // This creates the subtle slide in animation of the navigation
  @include transform(translateX($aside-width));
  @include max-screen($smart) {
    @include transform(translateX($aside-width - 50));
    width: $aside-width - 50;
  }
  &.aside-is-open {
    @include transform(translateX(0));
    visibility: visible;
    -webkit-transition: -webkit-transform $standard-transition 0s, visibility 0s 0s;
    -moz-transition: -moz-transform $standard-transition 0s, visibility 0s 0s;
    transition: transform $standard-transition 0s, visibility 0s 0s;
    // Smooth the scrolling on touch devices - webkit browsers
    -webkit-overflow-scrolling: touch;
  }
}

// Aside (Nav Items)
// ==========================================================================
.layout > aside {
  ul {
    padding-top: 20px;
    li {
      display: block;
      width: 100%;
    }
    a {
      @include transition(all $quick-transition ease);
      @include margin(0);
      @include padding(10px 30px);
      color: $white;
      display: block;
      font-size: $base-font-size - 4;
      @include max-screen($desktop) {
        font-size: $base-font-size - 5;
      }
      &:hover {
        background: $blue;
      }
    }
  }
}
