//
// Tables
// ==========================================================================

// Core
// ==========================================================================
table {
  font-size: 12px;
  display: table;
  vertical-align: top;
  max-width: 100%;
  color: $gray;
  // overflow-x: auto;
  // white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  thead {
    tr {
      border: 1px solid $gray-one;
      background: $gray-zero;
    }
  }
  tbody {
    tr:hover {
      background: $gray-one;
      > td.action {
        @include opacity(1);
      }
    }
  }
}

// Baseline
// ==========================================================================
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: $white;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      @include transition(all $quick-transition ease);
      > th,
      > td {
        @include transition(all $quick-transition ease);
        @include padding(15px);
        text-align: left;
        vertical-align: middle;
        border-top: 0;
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
  > thead > tr > th {
    font-size: 12px;
    font-family: $sans-font-medium;
    font-weight: normal;
    color: $blue;
  }
  > tbody > tr > td.action {
    @include opacity(0);
    a {
      @include size(20px);
      text-align: right;
      font-size: 16px;
    }
  }
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
}
