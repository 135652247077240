//
// Main (Global)
// ==========================================================================
html{
    background-color: rgb(17,62,91);
}

.noscroll {
    overflow: hidden !important;
}

.layout > main,
body > main {
  @include size(100%);
  @include margin(0);
  @include padding(0);
  @include transition-property(transform, padding);
  @include transition-duration($standard-transition);
  @include transform(translateZ(0));
  @include backface-visibility(hidden);
  min-height: 100vh;
  position: relative;
  z-index: $z-content;
  background-color: rgb(251, 251, 251);
  &:after {
    -webkit-transition: opacity $standard-transition 0s, visibility 0s $standard-transition;
    -moz-transition: opacity $standard-transition 0s, visibility 0s $standard-transition;
    transition: opacity $standard-transition 0s, visibility 0s $standard-transition;
    @include position(absolute, 0 0 0 0);
    @include opacity(0);
    visibility: hidden;
    background: rgba($blue, 0.9);
    content: "";
  }
  &.aside-is-open {
    @include transform(translateX(-$aside-width));
    @include max-screen($smart) {
      @include transform(translateX(-($aside-width - 50)));
    }
  }
  &.admin-nav-is-open {
    &:after {
      -webkit-transition: opacity $standard-transition 0s, visibility 0s 0s;
      -moz-transition: opacity $standard-transition 0s, visibility 0s 0s;
      transition: opacity $standard-transition 0s, visibility 0s 0s;
      @include opacity(1);
      visibility: visible;
    }
    .admin-nav {
      @include transform(translateX(($aside-width - 75)));
    }
    .admin-nav-toggle {
      position: absolute;
      left: 230px;
      z-index: $z-above-content;
      color: $white;
    }
  }
}


.dropdown {
    select{
        max-height: 41px;
        border-style: solid;
        border-color: rgba(black, 0);
        border-width: 1px;
        border-radius: 4px;
    }
    select:hover:focus, select:focus {
        border-style: solid;
        border-color: $gray-dark;
        border-width: 1px;
        border-radius: 4px;
    }
}
