//
// Tables
// ==========================================================================

// Core
// ==========================================================================
.construction-wrapper {
    position: relative;
}
.construction-image {
    height: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
.construction-image-container {
    opacity: .6;
    background-color: white;
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: url('../images/bg-constructionzone.png') repeat;
    background-size: contain;
    transition: opacity 1s;
}
.construction-image-container:hover{
    opacity: .5;
    cursor: pointer;
}
.construction-indicator-row {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 50px;
    display: table;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 1000;
}
.construction-indicator {
    width: 50px;
    height: 50px;
    display: table-cell;
}

.tooltip {outline:none; }
.tooltip strong {line-height:30px;}
.tooltip:hover {text-decoration:none;}
.tooltip span {
    z-index:10;
    display:none;
    padding:14px 20px;
    margin-top:30px;
    margin-left:28px;
    min-width:50px;
    max-width:300px;
    line-height:16px;
}
.tooltip:hover span{
    display:inline; position:absolute; color:#CCC;
    border:1px solid #DCA; background:#000000;}
.callout {z-index:20;position:absolute;top:30px;border:0;left:-12px;}
