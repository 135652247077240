.detailsDisplayItems .item-covered:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .detailsDisplayAnchor {
        display: none !important;
    }
    .detailsDisplayItems {
        display: inline !important;
    }
}
@media (max-width: 767px) {
    .detailsDisplayAnchor {
        display: inline;
    }
    .detailsDisplayItems {
        display: none;
    }
}
