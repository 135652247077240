//
// Header (Global)
// ==========================================================================
.layout > header,
body > header {
    @include transition(all $standard-transition ease);
    @include position(fixed, 0 0 auto 0);
    @include size(100% $header-height);
    @include clearfix;
    @include box-shadow(0, 2px, 10px, 0, rgba($black, 0.05));
    z-index: $z-above-content;
    background: rgba($white, 0.95);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    @include max-screen($tablet) {
        @include size(100% $header-height - 50);
    }

    &.aside-is-open {
        @include transform(translateX(-$aside-width));

        @include max-screen($smart) {
            @include transform(translateX(-($aside-width - 50)));
        }
    }

    &.header-is-scrolled {
        @include alpha-background-color(rgba($white, 0.95), $white);
        @include box-shadow(0, 1px, 5px, 0, rgba($black, 0.13));
        height: $header-height - 50;

        .container {
            @include padding(10px 0);
        }

        .logo {
            @include background-size(150px $logo-height);

            @include max-screen($smart) {
                @include background-size(150px 50px);
            }
        }
    }

    .container {
        @include transition(padding $standard-transition ease);
        @include padding(35px 0);
        line-height: 35px;

        @include max-screen($tablet) {
            @include padding(10px 0);
        }
    }
}

// Logo
// ==========================================================================
.layout > header,
body > header {
    .logo {
        @include transition (all $quick-transition ease);
        @include svg-bg(logo-landmark-horz, $logo-width $logo-height);
        @include size($logo-width $logo-height);
        @include margin(0);
        @include padding(0);
        float: left;
        cursor: pointer;
        margin-left: 50px;
        margin-top: -1px;
        @include max-screen($desktop) {
            @include background-size(180px $logo-height);
        }

        @include max-screen($tablet) {
            @include background-size(150px $logo-height);
        }

        @include max-screen("600px") {
            @include border-radius(100%);
            @include background-size(150px 50px);
            @include size(50px);
            margin-left: 10px;
            margin-top: 9px;
            margin-right: 10px;
            overflow: hidden;
        }
        @include max-screen("330px") {
            @include border-radius(100%);
            @include background-size(150px 50px);
            @include size(50px);
            margin-left: 4px;
            margin-top: 9px;
            margin-right: 4px;
            overflow: hidden;
        }

        &:hover {
            @include opacity(0.7);
            outline: none;
        }
    }

    .logo-bug {
        @include transition (all $quick-transition ease);
        @include svg-bg(logo-landmark-bug, $logo-bug-width $logo-bug-height);
        @include size($logo-bug-width $logo-bug-height);
        display: inline-block;
    }
}

// Nav
// ==========================================================================
.layout > header,
body > header {
    nav {
        @include transition(all $standard-transition ease);
        @include clearfix;
        @include margin(0);
        line-height: $logo-height;
        position: relative;
        z-index: $z-above-content;
        display: block;
        float: right;

        @include max-screen($tablet) {
            float: none;
        }

        .field-wrap {
            @include margin(15px 30px 0 0);
            height: 50px;

            @include max-screen($tablet) {
                @include position(absolute);
                @include center(x);
            }

            @include max-screen($smart) {
                margin-top: 10px;
            }

            .select select {
                padding-right: 50px !important;

                @include max-screen($tablet) {
                    padding-right: 0;
                }
            }
        }

        a {
            @include margin(0 30px 0 0);
            float: left;
            display: inline-block;
            position: relative;
            font-family: $sans-font-medium;
            font-size: $base-font-size - 4;

            @include max-screen($wide) {
                @include margin(0 16px 0 0);
            }

            @include max-screen($tablet) {
                @include margin(0 15px 0 15px);
            }

            @include max-screen($smart) {
                @include margin(0 10px 0 10px);
            }

            @include max-screen("330px") {
                @include margin(0 5px 0 5px);
            }

            @include max-screen($desktop) {
                font-size: $base-font-size - 6;
            }

            @include max-screen($tablet) {
                display: none;
            }

            &:before {
                @include margin(0 10px 0 0);
                font-size: 22px;
                vertical-align: middle;
                position: relative;
                top: -1px;

                @include max-screen($desktop) {
                    display: none;
                }
            }

            &:last-child {
                @include margin(0 0 0 10px);
            }

            &[data-icon="a"]:before {
                font-size: 24px;
                color: $orange;
                @include max-screen($tablet * .8) {
                    font-size: 20px;
                }

                @include max-screen($smart) {
                    font-size: 16px;
                }
            }

            &[data-icon="b"]:before {
                font-size: 23px;
                color: $blue;
                @include max-screen($tablet * .8) {
                    font-size: 19px;
                }
                @include max-screen($smart) {
                    font-size: 15px;
                }
            }

            &[data-icon="c"]:before {
                font-size: 24px;
                color: $teal;
                @include max-screen($tablet * .8) {
                    font-size: 20px;
                }
                @include max-screen($smart) {
                    font-size: 16px;
                }
            }

            &[data-icon="d"]:before {
                font-size: 24px;
                color: $gray;
                @include max-screen($tablet * .8) {
                    font-size: 20px;
                }
                @include max-screen($smart) {
                    font-size: 16px;
                }
            }
            &[data-icon="0"]:before {
                font-size: 24px;
                color: $green;
                @include max-screen($tablet * .8) {
                    font-size: 20px;
                }
                @include max-screen("615px") {
                    @include margin(0 0 0 0);
                }
                @include max-screen($smart) {
                    font-size: 16px;
                }
                @include max-screen("340px") {
                    @include margin(0 0 0 0);
                }
            }

            &[data-icon="Y"]:before {
                font-size: 24px;
                color: $orange;
                @include max-screen($tablet * .8) {
                    font-size: 20px;
                }

                @include max-screen($smart) {
                    font-size: 18px;
                }
            }

             &[data-icon="r"]:before {
                font-size: 23px;
                color: $blue;
                @include max-screen($tablet * .8) {
                    font-size: 21px;
                }
                @include max-screen($smart) {
                    font-size: 17px;
                }
            }

            &[data-icon="Z"]:before {
                font-size: 24px;
                color: $teal;
                @include max-screen($tablet * .8) {
                    font-size: 22px;
                }
                @include max-screen($smart) {
                    font-size: 18px;
                }
            }

             &[data-icon="1"]:before {
                font-size: 23px;
                color: $blue;
                @include max-screen($tablet * .8) {
                    font-size: 21px;
                }
                @include max-screen($smart) {
                    font-size: 17px;
                }
            }

            &[data-icon="2"]:before {
                font-size: 24px;
                color: $teal;
                @include max-screen($tablet * .8) {
                    font-size: 22px;
                }
                @include max-screen($smart) {
                    font-size: 18px;
                }
            }

            &[data-icon="+"]:before {
                font-size: 40px;
                color: $green;
                font-weight: bolder;
                top: -3px;
                @include max-screen($tablet * .8) {
                    font-size: 36px;
                }
                @include max-screen("615px") {
                    @include margin(0 0 0 0);
                }
                @include max-screen($smart) {
                    font-size: 32px;
                }
                @include max-screen("340px") {
                    @include margin(0 0 0 0);
                }
            }

            &[data-icon="7"]:before {
                font-size: 24px;
                color: $gray;
                @include max-screen($tablet * .8) {
                    font-size: 22px;
                }
                @include max-screen("615px") {
                    @include margin(0 0 0 0);
                }
                @include max-screen($smart) {
                    font-size: 18px;
                }
                @include max-screen("340px") {
                    @include margin(0 0 0 5px);
                }
            }

            &.btn {
                @include padding(0 20px);
                font-family: $sans-font-bold;
                font-size: 12px;
                line-height: 40px;
                margin-top: 15px;

                &:active {
                    @include box-shadow(0, 0, 0, 2px, $orange, true);
                }

                @include max-screen($tablet) {
                    @include position(absolute);
                    @include center(x);
                    display: block;
                    margin-top: 17px;
                    letter-spacing: 0;
                    line-height: 35px;
                }

                @include max-screen($smart) {
                    width: auto;
                }
            }

            &.aside-trigger {
                @include transition(top $quick-transition 0.2s, opacity $quick-transition ease);
                @include position(absolute, -100px 0 auto auto);
                line-height: $logo-height;
                font-weight: $bold;
                padding-left: 30px;

                &:hover {
                    @include opacity(0.7);
                }

                @include max-screen($tablet) {
                    display: block;
                    top: 100px;
                }

                @include max-screen($mobile) {
                    padding-left: 35px;
                    width: 30px;
                    overflow: hidden;
                }

                span {
                    // Menu Icon
                    @include position(absolute, 35px auto auto 0);
                    @include size(24px 2px);
                    @include margin(-1px 0 -10px 0);
                    @include transition(background-color $quick-transition);
                    @include border-radius(1px);
                    pointer-events: none;
                    background-color: $blue;

                    &:before,
                    &:after {
                        // Upper and lower lines of the menu icon
                        content: '';
                        position: absolute;
                        @include size(100%);
                        @include border-radius(1px);
                        background-color: $blue;
                        // Force Hardware Acceleration
                        @include transform(translateZ(0));
                        @include backface-visibility(hidden);
                        @include transition(all $quick-transition);
                    }

                    &:before {
                        @include transform(translateY(-6px));
                    }

                    &:after {
                        @include transform(translateY(6px));
                    }
                }
            }
        }
        .scaling-font-size {
            font-size: 12px;
            @include max-screen("400px") {
                font-size: 10px;
            }
            @include max-screen("350px") {
                font-size: 8px;
            }
            @include max-screen("319px") {
                display: none;
            }
        }
    }
}

// Nav (Active)
// ==========================================================================
.layout > header,
body > header {
    &.aside-is-open {
        nav {
            a.aside-trigger {
                span {
                    background-color: rgba($red, 0);

                    &:before {
                        @include transform(translateY(0) rotate(-45deg));
                    }

                    &:after {
                        @include transform(translateY(0) rotate(45deg));
                    }
                }
            }
        }
    }
}

// Header @ Post
// ==========================================================================
.layout > header.header-post,
body > header.header-post {
    @include box-shadow(0, 0, 0, 0, rgba($black, 0));
    background: transparent;

    &.header-is-scrolled {
        background: rgba($gray, 0.9);
    }

    .row {
        @include clearfix;
        position: relative;
        text-align: center;
    }

    nav {
        @include max-screen($tablet) {
            float: right;
        }
    }

    .btn-back {
        @include position(absolute, 0 auto auto 0);

        @include max-screen($tablet) {
            @include margin(7px 0 0 0);
            @include padding(0 20px);
            line-height: 40px;
        }

        @include max-screen($smart) {
            width: auto;
            display: inline-block;
        }

        i {
            @include margin(0 10px 0 0);
        }
    }

    .btn-subscribe {
        @include position(absolute, 0 0 auto auto);
        @include margin(0);
        @include padding(0 40px);
        line-height: 50px;

        @include max-screen($tablet) {
            @include margin(7px 0 0 0);
            @include padding(0 20px);
            line-height: 40px;
            right: 60px;
        }

        @include max-screen($smart) {
            display: none;
        }
    }
}

// Header @ Post
// ==========================================================================
.layout > header.header-admin,
body > header.header-admin {
    border-bottom: 1px solid $gray-one;
    position: absolute;

    .container {
        padding-left: 40px;
        padding-right: 10px;
        width: 100%;
        max-width: none;

        @include max-screen($tablet) {
            padding-left: 20px;
        }
    }
}

.visibleIfWillMoveRow {
    @include min-screen("947px") {
        display: none;
    }
}

.hiddenIfWillMoveRow {
    @include max-screen("946px") {
        display: none;
    }
}

.visibleIfMobile {
    @include min-screen($mobile + $pixeloffset) {
        display: none;
    }
}

.hiddenIfMobile {
    @include max-screen($mobile) {
        display: none;
    }
}

.hiddenIfSmallerThan375 {
    @include max-screen(em(374px)) {
        display: none;
    }
}

.hiddenIfSmallerThan750 {
    @include max-screen(em(750px)) {
        display: none;
    }
}

.visibleIfSmallerThan750{
    @include min-screen(em(751px)) {
        display: none;
    }
}

.visibleIfSmallerThanMobile {
    @include min-screen($mobile) {
        display: none;
    }
}

.hiddenIfSmallerThanMobile {
    @include max-screen($mobile - $pixeloffset) {
        display: none;
    }
}

.visibleIfTablet {
    @include min-screen($tablet + $pixeloffset) {
        display: none;
    }
}

.hiddenIfTablet {
    @include max-screen($tablet) {
        display: none;
    }
}

.header-image-offset {
    margin-left: 200px;
    @include max-screen("600px") {
        margin-left: 70px;
    }
}
.mobile-header-width {
    width: 16.5%;
    @include max-screen("500px"){
        width: 20%;
    }

}

.chatBox{
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 33px;
    background-color: #95CFD7;
    width: 66px;
    height: 66px;
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow ease .5s, width ease .5s;
    overflow:hidden;
    z-index: 45;
}
.chatBox:hover{
    box-shadow: 0px 0px 15px #333333;
    width:330px;
}
.chatBox img{
    margin-top: 16px;
    margin-left: 13px;
    width: 40px;
    height: 40px;
}
