//
// Post
// ==========================================================================

// Main @ Post
// ==========================================================================
main.post {
  .section-intro {
    padding-bottom: 50px;
  }
}

// Section Post Title
// ==========================================================================
.section-post-title {
  // 60px seems to properly adjust for the top header when changing sizes of screens.
  // Making it a variable so the value isn't ambigous.
  $vertical-adjustment: 60px;

  // Dynamic photo here
  @extend .section-intro;
  @include background-image-cover('../images/bg-post.jpg');

  // Centers the image across the entire size of the block.
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &.position-x-left {
    background-position-x: left;
  }

  &.position-y-top {
      // Doesn't work for smaller screens.
      @include min-screen($smart) {
        // Special case for laying out an image starting from the top of the image rather then vertically centered.
        background-position-y: $vertical-adjustment;
      }
  }

  &:after {
    @include opacity(0.9);
    background: $gray;
  }
  h1 {
    font-size: 50px;
    line-height: 54px;
    @include max-screen($smart) {
      @include margin(10px 0);
      font-size: 34px;
      line-height: 42px;
    }
  }
  figure {
    @include size(50px);
    @include margin(20px auto 0 auto);
    position: relative;
    display: block;
    img {
      @include border-radius(100%);
      width: 100%;
      display: block;
    }
  }
  .meta {
    @include margin(20px auto 0 auto);
    color: $white;
    font-size: 12px;
    line-height: 18px;
    font-style: italic;
    b {
      font-family: $sans-font-bold;
      font-weight: bold;
    }
    time {
      display: block;
    }
  }
}

// Section Posts
// ==========================================================================
.section-posts {
    background-color: white;
    padding-bottom: 30px;

    .container {
        max-width: 800px;

        > img {
            @include border-radius(4px);
            @include margin(40px auto);
            width: 100%;
            max-width: 100%;
            display: block;

            @include max-screen($smart) {
                @include margin(20px auto);
            }
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            padding-left: 40px;
        }
    }

    .author {
        @include margin(60px auto);
        @include padding(60px 0);
        text-align: center;
        border-top: 1px solid $gray-one;
        border-bottom: 1px solid $gray-one;

        figure {
            @include size(80px);
            @include margin(0 auto);
            position: relative;
            display: block;

            img {
                @include border-radius(100%);
                width: 100%;
                display: block;
            }
        }

        p {
            @include margin(0 auto);
            max-width: 690px;
        }

        .name {
            @include margin(20px auto);
            font-size: 22px;
            line-height: 26px;
            color: $gray;
        }
    }

    p a img {
        display: inline;
    }
}

// Section Posts
// ==========================================================================
.section-posts-additional {
  @extend .section-posts-intro;
  padding-top: 0;
  .row {
    @include margin(0);
  }
  h5 {
    margin-bottom: 30px;
    font-size: 22px;
  }
}
