//
// About
// ==========================================================================

// Main @ About
// ==========================================================================
main.about {
  .section-intro.about {
    h1 {
      font-size: 42px;
      line-height: 47px;
      margin-bottom: 20px;
      @include max-screen($desktop) {
        font-size: 32px;
        line-height: 42px;
      }
      @include max-screen($tablet) {
        @include margin(20px 0)
      }
      @include max-screen($smart) {
        @include margin(10px 0);
        font-size: 24px;
        line-height: 32px;
      }
    }
    h2 {
      font-family: $serif-subfont-regular;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    hr {
      margin-right: auto;
      margin-left: auto;
      max-width: 140px;
      background: $orange;
    }
  }
}

// Section About Social
// ==========================================================================
.section-about-social {
  @include padding(0);
  border-bottom: 1px solid $gray-one;
  @include max-screen($tablet) {
    border-bottom: none;
  }
  .container {
    width: 100%;
    max-width: none;
    .column {
      border-right: 1px solid $gray-one;
      background: $gray-zero;
      height: 230px;
      text-align: center;
      color: $orange;
      display: table;
      @include max-screen($tablet) {
        margin-bottom: 0;
        width: 33.33%;
        float: left;
        display: table;
        border-bottom: 1px solid $gray-one;
      }
      @include max-screen($smart) {
        height: 160px;
      }
      &:last-child {
        border-right: none;
      }
      a {
        color: $orange;
        font-size: 30px;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

// Section About
// ==========================================================================
.section-about {
  text-align: center;
  img {
    @include margin(0 auto 30px auto);
    width: 100%;
    max-width: 200px;
    display: block;
  }
  p {
    margin-right: auto;
    margin-left: auto;
    max-width: 620px;
  }
  .logos {
    @include margin(0 auto);
    display: table;
    @include max-screen($tablet) {
      display: block;
    }
    img {
      width: auto;
    }
  }
  .column {
    float: none;
    display: table-cell;
    vertical-align: middle;
    @include max-screen($tablet) {
      display: block;
    }
  }
}

// Section Team
// ==========================================================================
.section-team {
  border-top: 1px solid $gray-one;
  background: $gray-zero;
  text-align: center;
  h5 {
    font-family: $sans-font-light;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .inner {
    @include margin(15px 15px 40px 15px);
    @include padding(40px 20px);
    @include border-radius(4px);
    @include box-shadow(0, 0, 0, 2px, $gray-one);
    padding-top: 50px;
    padding-bottom: 20px;
    @include max-screen($wide) {
      @include margin(5px 5px 30px 5px);
      padding-top: 20px;
    }
  }
  figure {
    @include size(160px);
    @include margin(20px auto);
    position: relative;
    display: block;
    @include max-screen($wide) {
      height: auto;
      max-width: 160px;
      width: 100%;
    }
    img {
      @include border-radius(100%);
      width: 100%;
      display: block;
    }
  }
  .name {
    font-size: 14px;
    font-family: $sans-font-bold;
    letter-spacing: 1.2px;
    color: $blue;
    text-transform: uppercase;
    @include max-screen($desktop) {
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
  .meta {
    @extend .name;
    @include margin(0 auto 30px auto);
    color: $gray-two;
    font-size: 12px;
    @include max-screen($desktop) {
      margin-bottom: 10px;
      font-size: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 24px;
    @include max-screen($desktop) {
      font-size: 12px;
      line-height: 22px;
    }
  }
  .meta-title {
    @extend .name;
    @include margin(0 auto 0 auto);
    color: $gray-two;
    font-size: 12px;
    @include max-screen($desktop) {
      margin-bottom: 10px;
      font-size: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 24px;
    @include max-screen($desktop) {
      font-size: 12px;
      line-height: 22px;
    }
  }
  .links {
    @include margin(20px auto 0 auto);
    @include clearfix;
    display: inline-block;
    text-decoration: center;
    li {
      @include margin(0 10px);
      float: left;
      a {
        font-size: 22px;
        color: $orange;
      }
    }
  }
}
