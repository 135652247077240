/**
 * Display the focused item
 */
input:focus, select:focus, textarea:focus {
    border: 1px solid rgb(247, 195, 98) !important;
    border-radius: 4px;
}

.react-datepicker-popper {
    /* Ensure datepicker is always in front */
    z-index: 1000;
}
