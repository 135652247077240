@-webkit-keyframes flipInX {
	0% {-webkit-transform: perspective(400px) rotateX(90deg); opacity: 0;}
	40% {-webkit-transform: perspective(400px) rotateX(-10deg);}
	70% {-webkit-transform: perspective(400px) rotateX(10deg);}
	100% {-webkit-transform: perspective(400px) rotateX(0deg); opacity: 1;}
}
@-moz-keyframes flipInX {
	0% {-moz-transform: perspective(400px) rotateX(90deg); opacity: 0;}
	40% {-moz-transform: perspective(400px) rotateX(-10deg);}
	70% {-moz-transform: perspective(400px) rotateX(10deg);}
	100% {-moz-transform: perspective(400px) rotateX(0deg); opacity: 1;}
}
@-o-keyframes flipInX {
	0% {-o-transform: perspective(400px) rotateX(90deg); opacity: 0;}
	40% {-o-transform: perspective(400px) rotateX(-10deg);}
	70% {-o-transform: perspective(400px) rotateX(10deg);}
	100% {-o-transform: perspective(400px) rotateX(0deg); opacity: 1;}
}
@keyframes flipInX {
	0% {transform: perspective(400px) rotateX(90deg); opacity: 0;}
	40% {transform: perspective(400px) rotateX(-10deg);}
	70% {transform: perspective(400px) rotateX(10deg);}
	100% {transform: perspective(400px) rotateX(0deg); opacity: 1;}
}

@mixin flipInX($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(flipInX);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

.flipInX { @include flipInX(); }