//
// Intro (Group Type Heros & Content)
// ==========================================================================

// Intro
// ==========================================================================
.section-intro {
  @include size(100% auto);
  @include padding(200px 0 20px 0);
  position: relative;
  text-align: center;
  overflow: hidden;
  background-position: bottom;
  &:after {
    @include position(absolute, 0 0 0 0);
    @include opacity(0.8);
    content: "";
    display: block;
  }
  // For background-size:cover replacement on iOS devices
  @media only screen and (orientation: portrait) and (device-width: 320px), (device-width: 768px) {
    header {
      -webkit-background-size: auto 150%;
      background-attachment: scroll;
    }
  }
  @media only screen and (orientation: landscape) and (device-width: 320px), (device-width: 768px) {
    header {
      -webkit-background-size: 150% auto;
      background-attachment: scroll;
    }
  }
  @include max-screen($desktop) {
    @include padding(150px 0 20px 0);
    height: auto;
  }
  @include max-screen($tablet) {
    @include padding(150px 0 30px 0);
    height: auto;
  }
  @include max-screen($smart) {
    @include padding(110px 0 30px 0);
    background-position: left;
  }
  @include max-screen($mobile) {
    @include padding(110px 0 30px 0);
  }
  @include max-screen-height($ios-height) {
    @include padding(110px 0);
  }
  &.homeowner {
    @include background-image-cover('../images/bg-homeowner.jpg');
    &:after {
      background: $orange;
    }
  }
  &.professionals {
    @include background-image-cover('../images/bg-professionals.jpg');
    &:after {
      background: $blue;
    }
  }
  &.contractors {
    @include background-image-cover('../images/bg-contractors.jpg');
    padding-bottom: 120px;
    &:after {
      background: $teal;
    }
  }
  &.resources {
    @include background-image-cover('../images/bg-resources.jpg');
    padding-bottom: 120px;
    &:after {
      background: $gray;
    }
  }
  &.contact {
    @include background-image-cover('../images/bg-contact.jpg');
    padding-bottom: 120px;
    @include max-screen($tablet) {
      padding-bottom: 40px;
    }
    &:after {
      @include opacity(0.9);
      background: $blue;
    }
  }
  &.about {
    @include background-image-cover('../images/bg-about.jpg');
    padding-bottom: 80px;
    &:after {
      @include opacity(0.9);
      background: $blue;
    }
  }
  &.error {
    @include background-image-cover('../images/bg-error.jpg');
    &:after {
      @include opacity(0.9);
      background: $blue;
    }
  }
  &.misc {
    @include background-image-cover('../images/bg-misc.jpg');
    padding-bottom: 60px;
    @include max-screen($tablet) {
      padding-bottom: 40px;
    }
    @include max-screen($smart) {
      padding-bottom: 20px;
    }
    &:after {
      @include opacity(0.9);
      background: $blue;
    }
  }
  .container {
    text-align: center;
  }
}

// Intro Content
// ==========================================================================
.section-intro {
  .intro-content {
    @include clearfix;
    @include margin(0 auto);
    position: relative;
    max-width: 1260px;
    z-index: $z-content;
    text-align: center;
  }
  .intro-content-contact {
    @include clearfix;
    @include margin(0 auto);
    position: relative;
    z-index: $z-content;
    max-width: 900px;
    text-align: left;
    color: $white;
    .meta {
      text-align: left;
      padding-left: 40px;
      font-size: 16px;
      font-family: $sans-font-light;
      position: relative;
      @include max-screen($desktop) {
        padding-left: 0;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }
    }
    [data-icon]:before {
      @include position(absolute, 5px auto auto 0);
      font-size: 18px;
      color: $orange;
      @include max-screen($desktop) {
        @include margin(20px auto);
        position: relative;
        display: block;
        font-size: 16px;
      }
    }
  }
  .section-icon {
    @include margin(0 auto);
  }
  h1 {
    font-size: 62px;
    line-height: 60px;
    color: $white;
    @include max-screen($desktop) {
      font-size: 52px;
      line-height: 52px;
    }
    @include max-screen($tablet) {
      @include margin(20px 0)
    }
    @include max-screen($smart) {
      @include margin(10px 0);
      font-size: 34px;
      line-height: 42px;
    }
  }
  p {
    color: $white;
  }
  .btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    a {
      min-width: 200px;
      margin: 5px;
      @include max-screen($tablet) {
        float: left;
        margin-right: 30px;
        display: inline-block;
      }
      @include max-screen($smart) {
        float: none;
        margin-right: 0;
        display: block;
      }
    }
  }
}

// Intro Callouts
// ==========================================================================
.section-intro {
  .intro-callouts {
    @include margin(70px auto 0 auto);
    @include padding(20px 0 0 0);
    border-top: 1px solid rgba($white, 0.2);
    position: relative;
    z-index: $z-content;
    text-align: left;
    @include max-screen($tablet) {
      margin-top: 30px;
    }
    @include max-screen($smart) {
      margin-top: 20px;
    }
    .column {
      position: relative;
      @include max-screen($tablet) {
        @include padding(0);
      }
      &:first-child {
        padding-left: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
      &:last-child {
        padding-right: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
    }
    h4 {
      color: $white;
      > a {
        color: $white;
        i {
          @include margin(0 0 0 8px);
          @include position(relative, -2px 0 0 0);
          font-size: 14px;
          vertical-align: middle;
        }
      }
    }
    p {
      @include opacity(0.7);
      font-size: 16px;
      line-height: 24px;
      max-width: 260px;
      @include max-screen($tablet) {
        font-size: $base-font-size - 2;
        line-height: $base-line-height - 6;
        margin-bottom: 20px;
        max-width: none;
      }
      @include max-screen($smart) {
        font-size: $base-font-size - 4;
        line-height: $base-line-height - 6;
      }
    }
  }
}
