//
// Misc
// ==========================================================================

// Main @ Misc
// ==========================================================================
main.misc {
  .section-intro {
    .intro-content {
      max-width: 880px;
    }
    h1 {
      font-size: 42px;
      line-height: 47px;
      margin-bottom: 10px;
      @include max-screen($desktop) {
        font-size: 32px;
        line-height: 42px;
      }
      @include max-screen($tablet) {
        @include margin(20px 0)
      }
      @include max-screen($smart) {
        @include margin(10px 0);
        font-size: 24px;
        line-height: 32px;
      }
    }
    p {
      font-family: $sans-font-medium;
      font-size: 12px;
      color: $gray-two;
      letter-spacing: 1.8px;
    }
  }
}

// Section Misc
// ==========================================================================
.section-misc {
  .container {
    &.small {
      text-align: center;
      max-width: 570px;
    }
  }
  .title {
    color: $blue;
    font-family: $sans-font-bold;
  }
  img {
    @include margin(0 auto);
    display: block;
  }
  ul {
    @include padding(0 0 30px 40px);
    @include max-screen($tablet) {
      font-size: $base-font-size - 2;
      line-height: $base-line-height - 2;
      margin-bottom: 20px;
    }
    @include max-screen($smart) {
      @include padding(0 0 20px 20px);
      font-size: $base-font-size - 4;
      line-height: $base-line-height - 4;
    }
    li {
      list-style-type: disc;
      font-style: italic;
    }
  }
  .coverage-title {
    @include max-screen($desktop) {
      text-align: center;
    }
  }
  .features {
    @include margin(20px 0 0 0);
    width: 100%;
    @include max-screen($desktop) {
      text-align: center;
    }
    @include max-screen($smart) {
      float: none;
    }
    .column {
      position: relative;
      @include max-screen($tablet) {
        @include padding(0);
      }
      &:first-child {
        padding-left: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
      &:last-child {
        padding-right: 0;
        @include max-screen($tablet) {
          @include padding(0);
        }
      }
    }
    span {
      @include margin(0 0 20px 0);
      display: block;
      color: $green;
      font-family: $sans-font-bold;
      @include max-screen($wide) {
        font-size: 15px;
      }
    }
    .status {
      @include size(22px);
      @include border-radius(100%);
      @include position(relative, -2px auto auto auto);
      @include margin(0 10px 0 0);
      text-align: center;
      vertical-align: middle;
      border: 2px solid $green;
      display: inline-block;
      @include max-screen($desktop) {
        @include position(relative, auto auto auto auto);
        @include margin(0 auto 20px auto);
        display: block;
      }
      @include max-screen($smart) {
        @include margin(0 5px 0 0);
        display: inline-block;
      }
      &:after {
        @include position(absolute, 3px auto auto -1px);
        @include size(22px);
        line-height: 100%;
        text-transform: none;
        font-family: $icon-font-family;
        font-size: 12px;
        font-style: normal;
        color: $green;
        content: "u";
      }
    }
  }
}

// Section Misc Rekey
// ==========================================================================
.section-misc-rekey {
  background: $gray-zero;
  border-top: 1px solid $gray-one;
  text-align: center;
  figure {
    @include margin(0 auto 30px auto);
    max-width: 202px;
  }
}
